import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { HorizontalItems } from "~/app/Renew/HorizontalItems"
import { ThumbnailNew } from "~/app/Renew/Thumbnail"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { migrateHistories } from "~/libs/migration"
import { addPlayHistory, getPlayHistories, keys, setPlayHistories } from "~/libs/storage"
import { mixin } from "~/libs/style"

export type GamesProps = {
  games: Array<{
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    thumbnail: string
    image: { url: string }
    genres: number
  }>
}

const useHistories = (games: GamesProps["games"]) => {
  const [historyGames, setHistoryGames] = useState<GamesProps["games"]>([])

  useEffect(() => {
    migrateHistories()

    const gameIds = getPlayHistories(keys.paidHistory)
    const newHistoryGames = gameIds
      .map((gameId) => games.find((game) => game.gameId.toString() === gameId.toString()))
      .filter((game): game is NonNullable<typeof game> => game !== undefined)

    setPlayHistories(
      newHistoryGames.map((historyGame) => historyGame.gameId),
      keys.paidHistory
    )

    if (newHistoryGames.length) {
      setHistoryGames(newHistoryGames)
    }
  }, [])

  return historyGames
}

export const HistoryPaid: React.FC<GamesProps> = ({ games }) => {
  const historyGames = useHistories(games)
  const { analyticsTrack } = useTrackingNew()
  if (!historyGames.length) {
    return null
  }

  return (
    <Wrapper>
      <Title>
        <CrossheadText>最近プレイしたゲーム</CrossheadText>
      </Title>
      <HorizontalItemsStyled>
        {historyGames.map((game, index) => (
          <Item key={index}>
            <HorizontalLink
              key={index}
              href={game.url}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                analyticsTrack(
                  event,
                  "SiteTop_有料_最近プレイしたゲーム",
                  `${prefixNumber(index + 1)}個目`,
                  `${game.title}：${game.url}`
                )
                addPlayHistory(game.gameId)
              }}
            >
              <ThumbnailStyled src={game.image.url} width={100} height={100} />
              <GameTitle>{game.title}</GameTitle>
            </HorizontalLink>
          </Item>
        ))}
      </HorizontalItemsStyled>
    </Wrapper>
  )
}

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 23.2px;
  color: #3c3c3c;
`

const CrossheadText = styled.span`
  ${mixin.maxWidth}
  display: block;
  margin: 0 auto;
`

const Wrapper = styled.section`
  margin: 0 auto;
  padding: 24px 12px;
  padding-bottom: 0px;
  ${mixin.maxWidth}
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
  padding: 5px 0;
`

const Item = styled.li`
  margin-left: 4px;
  marign-right: 4px;
  padding: 8px;
  background: #ffffff;
  max-width: 100px;
  box-shadow: 0px 2px 2px 0px #00000026;
  border-radius: 6px;
  a {
    margin-right: 5px;
  }
  &:last-child a {
    margin-right: 10px;
  }
`

const GameTitle = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  margin-top: 8px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 13px;
  text-overflow: clip;
  white-space: normal;
  font-family: Noto Sans JP;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-align: left;
`

const ThumbnailStyled = styled(ThumbnailNew)`
  border-radius: 6px;
`

const HorizontalItemsStyled = styled(HorizontalItems)`
  padding-top: 5px;
  margin-left: -4px;
  margin-rigth: -4px;
`
