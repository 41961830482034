import getConfig from "next/config"
import Head from "next/head"
import React from "react"

import { Container } from "~/components/Container"
import { distributionTitle } from "~/config"
import { ExperimentProvider } from "~/contexts/Experiment"
import { Fetcher } from "~/external/Fetcher"
import { bustCache } from "~/libs/image"
import { ConfigPresenter } from "~/presenter/ConfigPresenter"
import { ConfigRepository } from "~/repository/ConfigRepository"

import { NavigationGameButton } from "./Paid//NavigationGameButton"
import { Banner } from "./Paid/Banner/Banner"
import { Header } from "./Paid/Header"
import { History } from "./Paid/History"
import { BannerPresenter } from "./Paid/model/presenter/BannerPresenter"
import { GameCatalogsPresenter } from "./Paid/model/presenter/GameCatalogsPresenter"
import { GamesPresenter } from "./Paid/model/presenter/GamesPresenter"
import { NoticeCatalogsPresenter } from "./Paid/model/presenter/NoticeCatalogsPresenter"
import { RankingPresenter } from "./Paid/model/presenter/RankingPresenter"
import { RecommendPresenter } from "./Paid/model/presenter/RecommendPresenter"
import { GameCatalogsRepository } from "./Paid/model/repository/GameCatalogsRepository"
import { NoticeCatalogsRepository } from "./Paid/model/repository/NoticeCatalogsRepository"
import { RankingRepository } from "./Paid/model/repository/RankingRepository"
import { RecommendRepository } from "./Paid/model/repository/RecommendRepository"
import { TopBannerRepository } from "./Paid/model/repository/TopBannerRepository"
import { NoticeCatalog } from "./Paid/NoticeCatalog"
import { PaidNote } from "./Paid/PaidFooter/Note"
import { PaidFooter } from "./Paid/PaidFooter/PaidFooter"
import { PaidGameCatalog } from "./Paid/PaidGameCatalog"
import { RankingBlock } from "./Paid/RankingBlock"
import { Recommend } from "./Paid/Recommend"
import { ToTopButton } from "./Paid/ToTopButton/ToTopButton"

type TopBannerSchema = {
  gameId: string
  app_id: number
  title: string
  url: string
  banner: string
}

type GamesProps = {
  gameId: number
  analyticsLabel: string
  title: string
  text: string
  url: string
  banner: string
  thumbnail: string
  image: {
    url: string
  }
}
type GamesBanner = {
  gameId: string
  app_id: number
  title: string
  text: string
  url: string
  banner: string
  thumbnail: string
  image: {
    url: string
  }
}
type GamesBannerToGame = {
  gameId: number
  title: string
  text: string
  url: string
  banner: string
  thumbnail: string
  image: {
    url: string
  }
}
type configExperimentsSchema = Array<{
  testId: string
  neta: string
  pattern?: string
}>
/*main schema props */
type PropsType = {
  title: string
  topBanners: TopBannerSchema[]
  gameCatalogs: GamesProps[]
  listMasterGame: GamesProps[]
  ranking: GamesProps[]
  recommend: GamesBanner[]
  masterGame: GamesBannerToGame[]
  noticesCatalogs: Array<{
    id: string
    title: string
    url: string
  }>
  config: {
    experiments: configExperimentsSchema
  }
  configDomain: {
    top: string
    paid: string
    free: string
  }
}

const PaidApp: React.FC<PropsType> = (props) => {
  const { topBanners, title, config, ranking, recommend, gameCatalogs, noticesCatalogs, configDomain, masterGame } =
    props
  const baseUrl = configDomain.paid

  return (
    <ExperimentProvider configExperiments={config.experiments}>
      <Head>
        <title>イージーゲーム（人気ガチ）</title>
        <meta
          name="description"
          content="国内外の様々なオンラインゲームを取り揃え、人気キャラクターもの、手応え充分のロールプレイングやシミュレーションなどが無料で遊べます"
        />
        <meta name="keywords" content="無料,ゲーム,無料ゲーム,スマホゲーム,ブラウザゲーム" />
        <meta name="apple-mobile-web-app-title" content="イージーゲーム"></meta>
        <meta property="og:title" content={`イージーゲーム（人気ガチ）`} />
        <meta property="og:site_name" content="イージーゲーム" />
        <meta property="og:image" content={`${baseUrl}assets/img/game_ogp_paid.png`} />
        <meta
          property="og:description"
          content="国内外の様々なオンラインゲームを取り揃え、人気キャラクターもの、手応え充分のロールプレイングやシミュレーションなどが無料で遊べます"
        />
        <meta property="og:url" content="https://games.portal.auone.jp/gachi/" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="イージーゲーム（人気ガチ）" />
        <meta
          name="twitter:description"
          content="国内外の様々なオンラインゲームを取り揃え、人気キャラクターもの、手応え抜群のロールプレイングやシミュレーションなどが無料で遊べます"
        />
        <link rel="canonical" href="https://games.portal.auone.jp/gachi/" />

        {/* Fluct用 */}
        <link rel="preload" as="script" href="https://pdn.adingo.jp/p.js" />
        <link rel="preconnect" href="https://sh.adingo.jp" crossOrigin="use-credentials" />
      </Head>

      <Container>
        <Header
          hasNotice={noticesCatalogs.length > 0}
          hasBackButton={true}
          backTitle={title}
          headerTitle={title}
          backUrl={configDomain.top}
          baseUrl={configDomain.top}
        />
        {noticesCatalogs.length > 0 ? <NoticeCatalog noticesCatalogs={noticesCatalogs} /> : ""}

        <Banner banners={topBanners} />
        <NavigationGameButton configDomain={configDomain} />
        {/* History Game */}
        <History games={masterGame} />

        <RankingBlock ranking={ranking} title="人気ガチゲームランキング" url="#" />
        <Recommend recommend={recommend} />
        <div>
          <PaidGameCatalog gameCatalogs={gameCatalogs} />
        </div>

        <PaidNote />
        <PaidFooter />
        <ToTopButton />

        <script src={bustCache("/gachi/assets/js/legacy.js")}></script>
      </Container>
    </ExperimentProvider>
  )
}

/*== Get Data for paid page */
const getPaidData = async () => {
  const {
    serverRuntimeConfig: { cmsEndpointPaid, cmsApiKeyPaid, configDomain }
  } = getConfig()
  const fetcher = new Fetcher({ cmsEndpoint: cmsEndpointPaid, cmsApiKey: cmsApiKeyPaid })

  // 5-TopBanner
  const bannersRepo = new TopBannerRepository(fetcher)
  const topBanners = await bannersRepo.findAll()
  // 3 Notice noticesCatalogs
  const noticeCatalogsRepo = new NoticeCatalogsRepository(fetcher)
  const noticesCatalogs = await noticeCatalogsRepo.findAll()

  // 6 history game

  /* 7 game ranking */
  const rankingRepo = new RankingRepository(fetcher)
  const ranking = await rankingRepo.findAll()
  /* 6 recommend */
  const recommendRepo = new RecommendRepository(fetcher)
  const recommendsContent = await recommendRepo.findAll()
  // const recommends = recommendsContent.
  const gameCatalogsRepo = new GameCatalogsRepository(fetcher)
  const gameCatalogs = await gameCatalogsRepo.findAll()
  const recommend = RecommendPresenter.transform(recommendsContent)
  // const gameRecommendBanners = await recommend.filter((banner) => {
  //   return banner.app_id > 0
  // })
  // let listBannerGame: unknown[] = []
  // if (gameRecommendBanners.length > 0) {
  //   const listBannerGameRecommend = GamesPresenter.transformBannerToGame(gameRecommendBanners)
  //   listBannerGame = listBannerGame.concat(listBannerGameRecommend)
  // }

  const topGameBanners = BannerPresenter.transform(topBanners)
  // const gameTopGameBanners = await topGameBanners.filter((banner) => {
  //   return banner.app_id > 0
  // })
  // if (gameTopGameBanners) {
  //   const listBannerGameTopBanner = GamesPresenter.transformBannerToGame(gameTopGameBanners)
  //   listBannerGame = listBannerGame.concat(listBannerGameTopBanner)
  // }
  /* Config Data */
  const configRepo = new ConfigRepository(fetcher)
  const config = await configRepo.findAll()
  const masterGame = GamesPresenter.transform([...ranking, ...gameCatalogs])
  const listMasterGame = [...masterGame]
  return {
    configDomain,
    title: distributionTitle,
    topBanners: topGameBanners,
    config: ConfigPresenter.transform(config),
    ranking: RankingPresenter.transform(ranking),
    recommend: recommend,
    gameCatalogs: GameCatalogsPresenter.transform(gameCatalogs),
    noticesCatalogs: NoticeCatalogsPresenter.transform(noticesCatalogs),
    masterGame: listMasterGame
  }
}

export { PaidApp, getPaidData }
