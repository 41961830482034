import { env } from "~/config"
import { adjustImgixParam, buildAssetsPath } from "~/libs/image"
import { RecommendFreeGamesSchema, RecommendPaidGamesSchema } from "~/repository/RecommendGamesRepository"
const isLocal = env === "local"
const buildBannerPath = buildAssetsPath("banner")

const adjustBannerParam = adjustImgixParam("banner")

type recommend = {
  app_id: number
  analytics: {
    category: string
    label: string
  }
  image: string
  url: string
  isPaid: boolean
}
type configDomain = { [key: string]: string }
export class RecommendGamesPresenter {
  static transform(
    freeGames: RecommendFreeGamesSchema,
    paidGames: RecommendPaidGamesSchema,
    configDomain: configDomain
  ) {
    const games = paidGames.contents.slice(0, 4).reduce<recommend[]>((result, content) => {
      if (content.game) {
        const { game } = content
        const image = !game.image.url
          ? ""
          : isLocal
          ? adjustBannerParam(game.image.url)
          : buildBannerPath(game.id, "paid")
        result.push({
          app_id: game.app_id ?? 0,
          analytics: {
            category: "SiteTop_おすすめゲーム",
            label: `${game.app_name}：${game.app_url}`
          },
          image,
          url: game.app_url,
          isPaid: true
        })
      }
      return result
    }, [])
    freeGames.contents.slice(0, 2).forEach((content) => {
      if (content.game) {
        const { game } = content
        const gameUrl = game.medibaHostingFlag ? configDomain.top.slice(0, -1) + game.portalUrl : game.portalUrl
        const image = !game.banner.url
          ? ""
          : isLocal
          ? adjustBannerParam(game.banner.url)
          : buildBannerPath(game.gameId)
        games.push({
          app_id: game.gameId,
          analytics: {
            category: "SiteTop_おすすめゲーム",
            label: [game.gameId, game.title, gameUrl].join("：")
          },
          image,
          url: gameUrl,
          isPaid: false
        })
      }
    })
    return games
  }
}
