import React from "react"
import styled from "styled-components"

import { mixin } from "~/libs/style"

interface HorizontalItemsProps {
  className?: string
}

export const HorizontalItems: React.FC<HorizontalItemsProps> = ({ className, children }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

const Wrapper = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-direction: rows;
  padding-top: 20px;
  margin: 0 auto;
  overflow-x: scroll;
  ${mixin.maxWidth}
`
