import React, { useEffect, useRef } from "react"
import styled from "styled-components"

import { useTracking } from "~/contexts/Tracking"
import { color, mixin } from "~/libs/style"
import { Genre as GenreSchema } from "~/repository/GenresRepository"

type GenreProps = {
  genres: GenreSchema[]
  selectHandler: React.Dispatch<React.SetStateAction<string>>
}

export const GenreSelect: React.FC<GenreProps> = ({ genres, selectHandler }) => {
  const ref = useRef<HTMLSelectElement>(null)
  const { analyticsTrack } = useTracking()
  const handler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget
    selectHandler(value)
    analyticsTrack(e, "ジャンル選択", value)
  }

  useEffect(() => {
    const selectedGenre = ref.current?.selectedOptions[0].value
    if (selectedGenre) {
      selectHandler(selectedGenre)
    }
  }, [])

  return (
    <Wrapper id="js-legacy-genreselect" suppressHydrationWarning>
      <Filter dir="rtl" onChange={handler} ref={ref}>
        <option value="">すべてのジャンル</option>
        {genres.map((genre, index) => (
          <option key={index} value={genre.code}>
            {genre.name}
          </option>
        ))}
      </Filter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${mixin.maxWidth}
  position: relative;
  padding: 0 10px;
  margin: 0 auto;
  text-align: right;
  &::before {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    content: "";
    border-top: 2px solid ${color.steelGray};
    border-right: 2px solid ${color.steelGray};
    transform: rotate(135deg) translate(-1px, 1px) scale(0.8);
  }
  &::after {
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    display: block;
    height: 1px;
    content: "";
    background-color: ${color.lightGray};
  }
`

const Filter = styled.select`
  position: relative;
  padding: 15px 23px;
  font-size: 16px;
  color: ${color.inkBlack};
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
`
