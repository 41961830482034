import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { PaidGameAnchor } from "~/app/Renew/PaidGameAnchor"
import { ThumbnailNew } from "~/app/Renew/Thumbnail"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { reproio } from "~/libs/repro"
import { addPlayHistory } from "~/libs/storage"
import { color, mixin, url } from "~/libs/style"

export type RankingItemProps = {
  gameId: number
  analyticsLabel: string
  title: string
  text: string
  url: string
  thumbnail: string
  banner: string
  genres: {
    code: string
    name: string
  }[]
  newFlag?: boolean
  updatedFlag?: boolean
}

export const Ranking: React.FC<{ ranking: RankingItemProps[]; anchorScrollTo: number }> = ({
  ranking,
  anchorScrollTo
}) => {
  const [toggleShowMore, setToggleShowMore] = useState(false)
  const [gameRankingOffset, setGameRankingOffset] = useState(500)
  const gameRankingRef = useRef<HTMLDivElement>(null)
  const handleToggleClick = () => {
    setToggleShowMore(!toggleShowMore)
  }
  const visibleItemCount = toggleShowMore ? 10 : 5
  const { analyticsTrack } = useTrackingNew()
  useEffect(() => {
    const offsetAfterMountRanking = gameRankingRef?.current?.offsetTop
    offsetAfterMountRanking && setGameRankingOffset(offsetAfterMountRanking)
  }, [gameRankingOffset])
  return (
    <Wrapper ref={gameRankingRef}>
      <PaidGameAnchor displayOffset={gameRankingOffset} scrollTo={anchorScrollTo} />
      <Header>
        <Title>無料ゲームランキング</Title>
        <a
          className="nav-link"
          href="/ranking"
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
            analyticsTrack(event, "SiteTop_無料ゲームランキング", "一覧>")
          }
        >
          一覧
        </a>
      </Header>
      <Items>
        {ranking.slice(0, visibleItemCount).map((gameCatalog, index) => {
          const items: JSX.Element[] = []
          const rank = index + 1
          items.push(<RankingItem key={index} gameCatalog={gameCatalog} rank={rank} isRankingPage={false} />)
          return items
        })}
      </Items>
      <ToggleButton onClick={handleToggleClick}>
        <span>
          {toggleShowMore ? (
            <a
              href="/ranking"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                analyticsTrack(event, "SiteTop_無料ゲームランキング", "ランキング一覧")
              }
            >
              ランキング一覧
            </a>
          ) : (
            <div
              onClick={(event: React.MouseEvent<HTMLSpanElement>) =>
                analyticsTrack(event, "SiteTop_無料ゲームランキング", "続きを見る")
              }
            >
              続きを見る
            </div>
          )}
        </span>
      </ToggleButton>
    </Wrapper>
  )
}

export const RankingItem: React.FC<{ gameCatalog: RankingItemProps; rank: number; isRankingPage: boolean }> = ({
  gameCatalog,
  rank,
  isRankingPage
}) => {
  const { analyticsTrack } = useTrackingNew()
  return (
    <li>
      <a
        href={gameCatalog.url}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (gameCatalog.newFlag) {
            reproio("track", "【click】NEWラベル")
          }
          analyticsTrack(
            event,
            isRankingPage ? "無料ゲーム一覧" : "SiteTop_無料ゲームランキング",
            `${prefixNumber(rank)}個目`,
            gameCatalog.analyticsLabel
          )
          addPlayHistory(gameCatalog.gameId)
        }}
      >
        <CardWrapper>
          <Rank rank={rank}>{rank}</Rank>
          <ThumbnailStyled src={gameCatalog.banner} width={170} height={78} />
          <Text>
            <Badge>{gameCatalog.genres[0].name}</Badge>
            <Body>
              {gameCatalog.newFlag ? (
                <LabelNew>NEW</LabelNew>
              ) : gameCatalog.updatedFlag ? (
                <LabelUpdate>更新</LabelUpdate>
              ) : null}
              <TitleGame>{gameCatalog.title}</TitleGame>
            </Body>
            <SubText>{gameCatalog.text}</SubText>
          </Text>
        </CardWrapper>
      </a>
    </li>
  )
}

const ToggleButton = styled.div`
  text-align: center;
  span {
    padding: 6px 35px 8px 21px;
    display: inline-block;
    border: 1px solid #34b2d9;
    border-radius: 20px;
    color: #34b2d9;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    a {
      color: #34b2d9;
    }
    &:after {
      content: ${url("/assets/img/renew/vector_down_2.svg")};
      position: absolute;
      right: 13px;
    }
    div {
      display: inline-block;
    }
  }
`

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0;
`
const TitleGame = styled.span`
  color: #3c3c3c;
`
const Wrapper = styled.section`
  background: #ffffff;
  margin: 0px auto;
  margin-bottom: 8px;
  ${mixin.maxWidth}
  padding: 24px 12px;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`

const Header = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  .nav-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.05em;
    display: block;
    position: relative;
    padding-right: 11px;
    &:after {
      content: ${url("/assets/img/renew/ranking_vector_right.svg")};
      position: absolute;
      right: 0;
    }
  }
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: left;
`

const Items = styled.ul`
  ${mixin.maxWidth}
  padding: 16px 0 24px;
  margin: 0 auto;
  background-color: white;
`

const Text = styled.div`
  flex: 1;
  padding: 7px 0 0 7px;
  overflow: hidden;
`

const Body = styled.p`
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: left;
`

const SubText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  font-size: 13px;
  line-height: 18.85px;
  color: #666666;
`

const LabelNew = styled.span`
  display: inline-block;
  width: 30px;
  padding: 3px 4px;
  margin: 4px 3px 0 0;
  font-size: 11px;
  line-height: 1;
  color: ${color.white};
  text-align: center;
  vertical-align: top;
  background-color: ${color.darkRed};
`

const LabelUpdate = styled(LabelNew)`
  background-color: ${color.cadmiumGreen};
`

const Rank = styled.div<{ rank: number }>`
  border-radius: 50%;
  background: #e2b647;
  text-align: center;
  justify-content: center;
  color: #fff;
  display: flex;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  margin-right: 7px;
  font-weight: 700;
  background-color: ${({ rank }) => {
    switch (rank) {
      case 1:
        return color.gold
      case 2:
        return color.silverGray
      case 3:
        return color.goldenrod
      default:
        return "#e4e4e4"
    }
  }};
  color: ${({ rank }) => {
    if (rank > 3) {
      return "#808080"
    } else {
      return "#fff"
    }
  }};
`

const Badge = styled.span`
  background: #febe1a;
  font-weight: 700;
  color: ${color.white};
  font-size: 12px;
  line-height: 17.4px;
  display: inline-block;
  padding: 2px 4px;
  border-radius: 2px;
`

const ThumbnailStyled = styled(ThumbnailNew)`
  border-radius: 5px;
`
