import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory } from "~/libs/storage"
import { mixin } from "~/libs/style"
export type PropsType = {
  banners: Array<{
    gameId: string
    app_id: number
    title: string
    url: string
    banner: string
  }>
}

export const Banner: React.FC<PropsType> = ({ banners }) => {
  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    mobileFirst: true
  }
  const { analyticsTrack } = useTrackingNew()
  const categoryTracking = "有料Top_有料ゲーム・特集バナー"
  return (
    <Wrapper>
      {banners.length > 0 ? (
        <Slider {...settings}>
          {banners.slice(0, 10).map((banner, index) => (
            <div key={index}>
              <Link
                href={banner.url}
                className="gachi-topbanner-p"
                data-gtm-bnum={` ${prefixNumber(index + 1)}個目 `}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  analyticsTrack(
                    event,
                    categoryTracking,
                    `${prefixNumber(index + 1)}個目`,
                    `${banner.title}：${banner.url}`
                  )
                  addPlayHistory(banner.app_id)
                }}
              >
                <Img src={banner.banner} />
              </Link>
            </div>
          ))}
        </Slider>
      ) : (
        <div></div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 5px auto 0px auto;
  ${mixin.maxWidth}
`
const Img = styled.img`
  max-width: 100%;
`
const Link = styled.a`
  position: relative;
  display: block;
`
