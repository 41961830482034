import styled, { css } from "styled-components"

import { color } from "~/libs/style"

type TopRankingLabelProps = {
  rank: number
}

export const TopRankingLabel: React.FC<TopRankingLabelProps> = ({ rank }) => {
  return <Wrapper rank={rank}>{rank}</Wrapper>
}

const Wrapper = styled.span<{ rank: number }>`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 30px;
  height: 16px;
  padding: 3px 0;
  font-size: 11px;
  color: ${color.white};
  text-align: center;
  background-color: ${({ rank }) => {
    switch (rank) {
      case 1:
        return color.gold
      case 2:
        return color.silverGray
      case 3:
        return color.goldenrod
      default:
        return color.dimGray
    }
  }};
  ${({ rank }) => (rank <= 3 ? TopThreeLabel : "")}
`

const TopThreeLabel = css`
  width: 38px;
  &::before,
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
  }

  &::before {
    top: 0;
    left: 0;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 8px 0 8px 5px;
  }

  &::after {
    top: 0;
    right: 0;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: 8px 5px 8px 0;
  }
`
