import React from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory, addPlayHistoryFreePage } from "~/libs/storage"
import { color, mixin } from "~/libs/style"

import { HeadLink } from "../HeadLink"

type RecommendProps = {
  configDomain: { [key: string]: string }
  recommend: Array<{
    app_id: number
    url: string
    image: string
    analytics: {
      category: string
      label: string
    }
    isPaid: boolean
  }>
}

export const Recommend = React.forwardRef(({ recommend, configDomain }: RecommendProps, ref) => {
  const { analyticsTrack } = useTrackingNew()
  return (
    <>
      <HeadLink title="おすすめゲーム" />
      <Wrapper>
        <List>
          {recommend.map((game, index) => (
            <Item key={index}>
              <HorizontalLink
                key={index}
                href={game.url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  analyticsTrack(
                    event,
                    game.analytics.category,
                    `${prefixNumber(index + 1)}個目`,
                    `${game.analytics.label}`
                  )
                  if (game.isPaid) {
                    addPlayHistory(game.app_id)
                  } else {
                    addPlayHistoryFreePage(game.app_id, configDomain.free, ref)
                  }
                }}
              >
                <Img src={game.image} alt="" loading="lazy" onLoad={imageOnLoadHandler} />
              </HorizontalLink>
            </Item>
          ))}
        </List>
      </Wrapper>
    </>
  )
})
Recommend.displayName = "Recommend"

const imageOnLoadHandler = (e: React.SyntheticEvent<HTMLImageElement>) => {
  e.currentTarget.style.backgroundColor = color.white // 背景透過サムネイルのために変更
}
const Img = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`
const Wrapper = styled.section`
  margin: 0 auto;
  padding: 10px 0;
  ${mixin.maxWidth}
`
const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Item = styled.li`
  width: 50%;
  padding-bottom: 5px;
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
`
