import React from "react"
import styled from "styled-components"

import { Thumbnail } from "~/components/Thumbnail"
import { color, mixin } from "~/libs/style"

type CardProps = {
  imageURL: string
  className?: string
}

export const Card: React.FC<CardProps> = ({ children, imageURL, className }) => {
  return (
    <Wrapper className={className}>
      <Thumbnail src={imageURL} />
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: block;
    margin: auto;
    content: "";
    background-color: ${color.choke};
    border: 1px solid ${color.lightGray};
    ${mixin.thumbnail}
  }
`
