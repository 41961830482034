import styled from "styled-components"

import { mixin } from "~/libs/style"

export const HorizontalItems: React.FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-direction: rows;
  padding: 10px 0 0 10px;
  margin: 0 auto;
  overflow-x: scroll;
  ${mixin.maxWidth}
`
