import React from "react"
import styled from "styled-components"

import { color, mixin } from "~/libs/style"

export const Note: React.FC = () => {
  return (
    <Wrapper>
      <Items>
        <Title>推奨環境</Title>
        <Item>・簡単ゆるゲーム</Item>
        <ItemChild>Android 5以上（Chrome 51以上）</ItemChild>
        <ItemChild>iOS 12.1.3以上（Safari 12以上）</ItemChild>
        <Br />
        <Item>・人気ガチゲーム</Item>
        <ItemChild>Android 最新版（Chrome 最新版）</ItemChild>
        <ItemChild>iPhone 最新版（Safari 最新版）</ItemChild>
        <Br />
        <ItemChild>※iOSではゲームの履歴が正常に表示されない場合があります。</ItemChild>
        <Title>注意事項</Title>
        <Item>・不安定な通信環境では正常に遊べない場合があります。</Item>
        <Item>・端末、OS、ブラウザのバージョンによっては正常に遊べない場合があります。</Item>
        <Item>・プライベートブラウザモードでは正常に遊べない場合があります。</Item>
        <Item>・広告ブロックを設定すると正常に遊べない場合があります。</Item>
        <Item>・ブラウザのCookieやキャッシュをクリアすると、得点などの記録が消去されます。</Item>
        <Item>
          ・iOS 13.4以上の環境では7日以上の遊ばない期間が発生すると、得点などの記録が消去される場合があります。
        </Item>
        <Item>・本サービスは日本国内専用です。</Item>
        <Item>・This service is for Japan only.</Item>
      </Items>
    </Wrapper>
  )
}

type NoteFreeGameProps = {
  url: string
}
export const NoteFreeGame: React.FC<NoteFreeGameProps> = ({ url }) => {
  return (
    <NoteFreeGameWrapper>
      <NoteFreeGameContent>
        <span>
          従来の無料ゲームは「<a href={url}>簡単ゆるゲーム</a>」から遊べます
        </span>
      </NoteFreeGameContent>

      <NoteFreeGameContent>
        <p>「人気ガチゲーム」は外部サイトに移動します</p>
      </NoteFreeGameContent>
    </NoteFreeGameWrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px 10px;
  background-color: ${color.whiteSmoke};
`

const Items = styled.dl`
  ${mixin.maxWidth}
  margin: 0 auto;
`

const Title = styled.dt`
  height: 20px;
  padding-left: 6px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: ${color.dimGray};
  border-left: 3px solid ${color.dimGray};
  &:first-child {
    margin-top: 0;
  }
`
const Text = styled.dd`
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.2;
`

const Item = styled(Text)`
  padding-left: 1em;
  text-indent: -1em;
`

const ItemChild = styled(Text)`
  padding-left: 1.1em;
`

const NoteFreeGameWrapper = styled.div`
  padding: 0 10px 20px;
`
const NoteFreeGameContent = styled.div`
  ${mixin.maxWidth}
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  a {
    color: ${color.blue};
    text-decoration: underline;
  }
  p {
    padding-top: 15px;
  }
`

const Br = styled.br``
