import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { ThumbnailNew } from "~/app/Renew/Thumbnail"
import { AdInfeed } from "~/components/Ad"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { reproio } from "~/libs/repro"
import { addPlayHistory } from "~/libs/storage"
import { color, mixin, url } from "~/libs/style"
import { Genre as GenreSchema } from "~/repository/GenresRepository"

export type GameCatalogProps = {
  gameCatalogs: Array<{
    newFlag: boolean
    updatedFlag: boolean
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    thumbnail: string
    banner: string
    genres: Array<{
      code: string
      name: string
    }>
  }>
  genres: GenreSchema[]
  adInfeedId: number
  showAll?: boolean
  isGameListPage?: boolean
}

const infeedAdPosition = 6

export const GameCatalogNew: React.FC<GameCatalogProps> = ({
  gameCatalogs,
  genres,
  adInfeedId,
  showAll,
  isGameListPage
}) => {
  const { analyticsTrack } = useTrackingNew()
  const [activeGenre, setActiveGenre] = useState("")
  const pageNumber = 1
  const itemsPerPage = 9
  const startIndex = (pageNumber - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const filteredGameCatalogs = gameCatalogs
    .filter((gameCatalog) => {
      if (activeGenre) {
        return gameCatalog.genres.some((genre) => genre.code === activeGenre)
      }
      return true
    })
    .slice(0, showAll ? gameCatalogs.length : endIndex)

  return (
    <Wrapper>
      <Header>
        <Title>無料ゲーム一覧</Title>
      </Header>
      <GenreSelect genres={genres} selectHandler={setActiveGenre} isGameListPage={isGameListPage} />
      <ItemWrapper>
        <Items>
          {filteredGameCatalogs.flatMap((gameCatalog, index) => {
            const items: JSX.Element[] = []

            if ((index || 1) % infeedAdPosition === 0) {
              const targetId = `adg_infeed_${activeGenre || "ALL"}_${index}`
              items.push(
                <li key={targetId} className="js-legacy-infeed" suppressHydrationWarning>
                  <AdInfeed id={adInfeedId} targetId={targetId} />
                </li>
              )
            }

            items.push(
              <li key={index}>
                <a
                  href={gameCatalog.url}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    if (gameCatalog.newFlag) {
                      reproio("track", "【click】NEWラベル")
                    }
                    analyticsTrack(
                      event,
                      isGameListPage ? "無料ゲーム一覧" : "SiteTop_無料ゲーム一覧",
                      `${prefixNumber(index + 1)}個目`,
                      gameCatalog.analyticsLabel
                    )
                    addPlayHistory(gameCatalog.gameId)
                  }}
                >
                  <Card imageURL={gameCatalog.banner}>
                    <Text>
                      <Body>
                        {gameCatalog.newFlag ? (
                          <LabelNew>NEW</LabelNew>
                        ) : gameCatalog.updatedFlag ? (
                          <LabelUpdate>更新</LabelUpdate>
                        ) : null}
                        <TitleGame>{gameCatalog.title}</TitleGame>
                      </Body>
                      <SubText>{gameCatalog.text}</SubText>
                    </Text>
                  </Card>
                </a>
              </li>
            )
            return items
          })}
        </Items>
      </ItemWrapper>
      {!showAll && (
        <LoadMore>
          <span>
            <a
              href="/list"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                analyticsTrack(event, "SiteTop_無料ゲーム一覧", "無料ゲーム一覧")
              }
            >
              無料ゲーム一覧
            </a>
          </span>
        </LoadMore>
      )}
    </Wrapper>
  )
}

const Header = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  margin-bottom: 16px;
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: left;
`
const TitleGame = styled.span`
  color: #3c3c3c;
`

const LoadMore = styled.div`
  text-align: center;
  margin-top: 20px;
  span {
    width: 147px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    border: 1px solid #34b2d9;
    border-radius: 20px;
    color: #34b2d9;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    a {
      color: #34b2d9;
    }
    &:after {
      content: ${url("/assets/img/renew/vector_right.svg")};
      margin-left: 15px;
      margin-right: -15px;
    }
  }
`

const Wrapper = styled.section`
  background: #ffffff;
  margin: 0px auto;
  ${mixin.maxWidth}
  padding: 24px 12px;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`

const ItemWrapper = styled.div`
  margin: 0 auto;
  ${mixin.maxWidth}
`

const Items = styled.ul`
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  li {
    flex-basic: 50%;
    width: 50%;
    box-sizing: border-box;
    padding: 0 6px 12px;
  }
`

const Text = styled.div`
  flex: 1;
  padding-top: 6px;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
`

const Body = styled.p`
  overflow: hidden;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`

const SubText = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
`

const LabelNew = styled.span`
  display: inline-block;
  width: 30px;
  padding: 3px 4px;
  margin: 4px 3px 0 0;
  font-size: 11px;
  line-height: 1;
  color: ${color.white};
  text-align: center;
  vertical-align: top;
  background-color: ${color.darkRed};
`

const LabelUpdate = styled(LabelNew)`
  background-color: ${color.cadmiumGreen};
`

type GenreProps = {
  genres: GenreSchema[]
  selectHandler: React.Dispatch<React.SetStateAction<string>>
  isGameListPage?: boolean
}

export const GenreSelect: React.FC<GenreProps> = ({ genres, selectHandler, isGameListPage }) => {
  const [selectedGenre, setSelectedGenre] = useState<string>("")
  const { analyticsTrack } = useTrackingNew()

  const handleSelect = (event: React.MouseEvent<HTMLLIElement, MouseEvent> | null, genre: string) => {
    setSelectedGenre(genre)
    selectHandler(genre)
    if (event !== null) {
      analyticsTrack(event, isGameListPage ? "無料ゲーム一覧" : "SiteTop_無料ゲーム一覧", "ジャンルタブ", genre)
    }
  }

  useEffect(() => {
    if (selectedGenre === "") {
      const defaultGenre = genres[0]?.code
      handleSelect(null, defaultGenre)
    }
  }, [genres])

  return (
    <WrapperGenre id="js-legacy-genreselect" suppressHydrationWarning>
      <ul>
        {genres.map((genre, index) => (
          <li
            key={index}
            onClick={(event: React.MouseEvent<HTMLLIElement>) => handleSelect(event, genre.code)}
            className={selectedGenre === genre.code ? "active" : ""}
          >
            {genre.name}
          </li>
        ))}
      </ul>
    </WrapperGenre>
  )
}

const WrapperGenre = styled.div`
  ul {
    display: flex;
    overflow-x: scroll;
    background: #ffffff;
    margin-bottom: 20px;
    li {
      padding: 11px 9px 11px 9px;
      border-left: 1px solid #e6e6e6;
      word-break: keep-all;
      font-size: 13px;
      font-weight: 400;
      border: 1px solid #e6e6e6;
      border-left: 0;
    }
    li:first-child {
      border-radius: 8px 0 0 8px;
      border-left: 1px solid #e6e6e6;
    }
    li:last-child {
      border-radius: 0 8px 8px 0;
    }
    li.active {
      background: #34b2d9;
      color: #ffffff;
    }
  }
`
const ThumbnailStyled = styled(ThumbnailNew)`
  border-radius: 6px;
  max-width: 100%;
  height: auto;
`
type CardProps = {
  imageURL: string
  className?: string
}

export const Card: React.FC<CardProps> = ({ children, imageURL, className }) => {
  return (
    <CardWrapper className={className}>
      <ThumbnailStyled src={imageURL} width={189} height={86} />
      {children}
    </CardWrapper>
  )
}

const CardWrapper = styled.div``
