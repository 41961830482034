import React from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { mixin } from "~/libs/style"

type NoticeProps = {
  noticesCatalogs: Array<{
    title: string
    url: string
  }>
}

export const NoticeCatalog: React.FC<NoticeProps> = ({ noticesCatalogs }) => {
  const { analyticsTrack } = useTrackingNew()
  if (noticesCatalogs.length < 1) {
    return null
  }
  return (
    <Wrapper>
      <NoticeList>
        <Title>
          <span>お知らせ</span>
        </Title>
        <List>
          {noticesCatalogs.slice(0, 5).map((notice, index) => (
            <Item key={index}>
              <Link
                href={notice.url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                  analyticsTrack(event, "SiteTop_お知らせ", notice.title, notice.url)
                }
              >
                {notice.title}
              </Link>
            </Item>
          ))}
        </List>
      </NoticeList>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: #ffffff;
`
const NoticeList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;
  ${mixin.maxWidth}
  padding-top: 20px;
`
const Title = styled.h2`
  color: #3c3c3c;
  size: 16px;
  font-weight: 700;
  line-height: 28.6px;
  flex-basic: 67px;
`
const List = styled.ul`
  list-style: disc;
  padding-left: 30px;
  flex: 1;
`
const Item = styled.li`
  font-size: 13px;
  font-weight: 400;
  line-height: 28.6px;
`
const Link = styled.a`
  ${mixin.maxWidth}
  position: relative;
  box-sizing: border-box;
  display: block;
  padding-bottom: 10px;
  margin: 0 auto;
  font-size: 14px;
`
