import getConfig from "next/config"
import Head from "next/head"
import React, { useEffect, useRef, useState } from "react"

import { ToTopButton } from "~/app/Top/ToTopButton"
import { Banner } from "~/components/Banner"
import { Container } from "~/components/Container"
import { FirstViewModel } from "~/components/FirstViewModel"
import { NavigationGameButton } from "~/components/NavigationGameButton"
import { RankingProps } from "~/components/Ranking"
import { RankingBlock } from "~/components/RankingBlock"
import { Recommend } from "~/components/Recommend"
import { distribution, distributionTitle } from "~/config"
import { ExperimentProvider } from "~/contexts/Experiment"
import { TrackingProvider } from "~/contexts/Tracking"
import { Fetcher } from "~/external/Fetcher"
import { useRepro } from "~/hooks/useRepro"
import { bustCache } from "~/libs/image"
import { BannersTopPresenter } from "~/presenter/BannersTopPresenter"
import { ConfigPresenter } from "~/presenter/ConfigPresenter"
import { NoticeCatalogsTopPresenter } from "~/presenter/NoticeCatalogsTopPresenter"
import { PopularGameCatalogsPresenter } from "~/presenter/PopularGameCatalogsPresenter"
import { RankingPresenter } from "~/presenter/RankingPresenter"
import { RecommendGamesPresenter } from "~/presenter/RecommendGamesPresenter"
import { BannersFreeRepository, BannersPaidRepository } from "~/repository/BannersRepository"
import { ConfigRepository } from "~/repository/ConfigRepository"
import { NoticeCatalogsTopRepository } from "~/repository/NoticeCatalogsTopRepository"
import { PopularGameCatalogsSchema } from "~/repository/PopularGameCatalogsRepository"
import { PopularGameCatalogsRepository } from "~/repository/PopularGameCatalogsRepository"
import { RankingRepository } from "~/repository/RankingRepository"
import { RecommendFreeGamesRepository, RecommendPaidGamesRepository } from "~/repository/RecommendGamesRepository"

import { Footer } from "./Top/Footer"
import { Header } from "./Top/Header"
import { Note, NoteFreeGame } from "./Top/Note"
import { NoticeCatalog } from "./Top/NoticeCatalog"
import { RankingBlockPaid } from "./Top/RankingBlockPaid"

type Experiment = {
  testId: string
  neta: string
}

interface ConfigProps {
  experiments: Experiment[]
}

export type TopAppProps = {
  ranking: RankingProps["ranking"]
  config: ConfigProps
  banners: {
    app_id: number
    url: string
    banner: string
    analytics: {
      category: string
      label: string
    }
    isPaid: boolean
  }[]
  recommendGames: {
    app_id: number
    analytics: {
      category: string
      label: string
    }
    image: string
    url: string
    isPaid: boolean
  }[]
  noticesCatalogsTop: {
    id: string
    title: string
    url: string
  }[]
  popularGameCatalogs: Array<PopularGameCatalogsSchema>
  configDomain: {
    top: string
    paid: string
    free: string
  }
}
const TopApp: React.FC<TopAppProps> = (props) => {
  const [gameCatalogOffset, setGameCatalogOffset] = useState(500)
  const gameCatalogRef = useRef<HTMLDivElement>(null)
  const { popularGameCatalogs, noticesCatalogsTop, recommendGames, ranking, config, banners, configDomain } = props
  useRepro(distribution)

  useEffect(() => {
    const offsetAfterMount = gameCatalogRef?.current?.offsetTop
    offsetAfterMount && setGameCatalogOffset(offsetAfterMount)
  }, [gameCatalogOffset])
  const baseUrl = configDomain.top
  const storageRef = useRef(null)
  return (
    <ExperimentProvider configExperiments={config.experiments}>
      <Head>
        <meta
          name="description"
          content="人気のオンラインゲームから、簡単なカジュアルゲームまで、国内外から様々な種類をご用意。大人から子供まで、短時間でも長時間でも、無料で遊べます"
        />
        <meta property="og:title" content="イージーゲーム（人気ガチ、簡単ゆる）" />
        <meta property="og:site_name" content="イージーゲーム" />
        <meta property="og:image" content={`${baseUrl}assets/img/game_ogp_top.png`} />
        <meta
          property="og:description"
          content="人気のオンラインゲームから、簡単なカジュアルゲームまで、国内外から様々な種類をご用意。大人から子供まで、短時間でも長時間でも、無料で遊べます"
        />
        <meta property="keywords" content="無料,ゲーム,無料ゲーム,スマホゲーム,ブラウザゲーム" />
        <meta name="apple-mobile-web-app-title" content="イージーゲーム" />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="イージーゲーム（人気ガチ、簡単ゆる）" />
        <meta
          name="twitter:description"
          content="人気のオンラインゲームから、簡単なカジュアルゲームまで、国内外から様々な種類をご用意。大人から子供まで、短時間でも長時間でも、無料で遊べます"
        />
        <link rel="canonical" href={baseUrl} />

        {/* Fluct用 */}
        <link rel="preload" as="script" href="https://pdn.adingo.jp/p.js" />
        <link rel="preconnect" href="https://sh.adingo.jp" crossOrigin="use-credentials" />

        <title>イージーゲーム（人気ガチ、簡単ゆる）</title>
      </Head>
      <TrackingProvider categoryProp="ゲームトップ">
        <Container>
          <Header
            baseUrl={configDomain.top}
            hasNotice={noticesCatalogsTop.length > 0}
            headerTitle={distributionTitle}
            iconText="ポータルTOP"
          />
          <NoticeCatalog noticesCatalogs={noticesCatalogsTop} />
          <Banner banner={banners} configDomain={configDomain} ref={storageRef} />
          <NavigationGameButton configDomain={configDomain} />
          <NoteFreeGame url={configDomain.free} />
          <RankingBlockPaid
            ranking={popularGameCatalogs}
            title="人気ガチゲームランキング"
            url={configDomain.paid}
            moreText="人気ガチゲーム（基本無料）へ>"
          />
          <RankingBlock
            ranking={ranking}
            title="簡単ゆるゲームランキング"
            configDomain={configDomain}
            moreText="簡単ゆるゲーム（無料）へ>"
            ref={storageRef}
          />
          <Recommend recommend={recommendGames} configDomain={configDomain} ref={storageRef} />
          <Note />
          <Footer />
          <ToTopButton />
          <script src={bustCache("/assets/js/legacy.js")} />
          <iframe ref={storageRef} src={configDomain.free + "localstorage"} style={{ width: 0, height: 0 }} />
          <FirstViewModel baseUrl={configDomain.top} />
        </Container>
      </TrackingProvider>
    </ExperimentProvider>
  )
}

const getTopData = async (): Promise<TopAppProps> => {
  const {
    serverRuntimeConfig: { cmsEndpoint, cmsApiKey, cmsEndpointPaid, cmsApiKeyPaid, configDomain }
  } = getConfig()
  const fetcher = new Fetcher({ cmsEndpoint, cmsApiKey })
  const fetcherPaid = new Fetcher({
    cmsEndpoint: cmsEndpointPaid,
    cmsApiKey: cmsApiKeyPaid
  })

  const noticeCatalogsTopRepo = new NoticeCatalogsTopRepository(fetcherPaid)
  const noticesCatalogsTop = await noticeCatalogsTopRepo.findAll()

  const rankingRepo = new RankingRepository(fetcher)
  const ranking = await rankingRepo.findAll()

  const configRepo = new ConfigRepository(fetcher)
  const config = await configRepo.findAll()

  const bannersPaidRepo = new BannersPaidRepository(fetcherPaid)
  const bannersPaid = await bannersPaidRepo.findAll()

  const bannerFreeRepo = new BannersFreeRepository(fetcher)
  const bannersFree = await bannerFreeRepo.findAll()

  const recommendGamesPaidRepo = new RecommendPaidGamesRepository(fetcherPaid)
  const recommendGamesPaid = await recommendGamesPaidRepo.findAll()

  const recommendGamesFreeRepo = new RecommendFreeGamesRepository(fetcher)
  const recommendGamesFree = await recommendGamesFreeRepo.findAll()

  const popularGameCatalogsRepo = new PopularGameCatalogsRepository(fetcherPaid)
  const popularGameCatalogs = await popularGameCatalogsRepo.findAll()
  return {
    banners: BannersTopPresenter.transform(bannersFree, bannersPaid, configDomain),
    recommendGames: RecommendGamesPresenter.transform(recommendGamesFree, recommendGamesPaid, configDomain),
    noticesCatalogsTop: NoticeCatalogsTopPresenter.transform(noticesCatalogsTop),
    ranking: RankingPresenter.transform(ranking),
    config: ConfigPresenter.transform(config),
    popularGameCatalogs: PopularGameCatalogsPresenter.transform(popularGameCatalogs),
    configDomain: configDomain
  }
}

export { TopApp, getTopData }
