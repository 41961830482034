import React, { useEffect, useState } from "react"
export type Props = {
  baseUrl?: string
}
export const FirstViewModel: React.FC<Props> = ({ baseUrl }) => {
  const localStorageKey = "firstViewModel"
  const [isOpen, setIsOpen] = useState(false)
  const popupStyles = `
    .model-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1049;
      background: rgba(0, 0, 0, 0.6);
    }
    .model {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .model-dialog {
      position: relative;
      width: auto;
      margin: 34px 20px;
    }
    .model-content {
      position: relative;
      background-color: #fff;
      min-height: 500px;
      max-width: 1000px;
      margin: 0 auto;
    }
    .model-container h2 {
      margin-top: 0;
    }
    .model-header {
      text-align: center;
    }
    .model-container button {
      margin-top: 10px;
    }
    .popup-close {
      position: absolute;
      top: 6px;
      right: 0px;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 18px;
    }
    .model-body {
      padding: 16px;
    }
    .btn {
      background: #D32D33;
      border-radius: 8px;
      height: 48px;
      width: 100%;
      border: none;
    }
    .btn a {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .block {
      border: 2px solid #D32D33;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .block-header {
      background: #D32D33;
      color: #FFFFFF;
      text-align: center;
      height: 26px;
      line-height: 26px;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0;
    }
    .col {
      flex: 1;
      text-align: center;
    }
    .col-inner {
      padding: 0 10px;
    }
    .col + .col {
      border-left: 2px solid rgba(211, 45, 51, 0.2);
    }
    .img-responsive {
      max-width: 100%;
      height: auto;
    }
  `

  useEffect(() => {
    if (isFirstView()) {
      setIsOpen(true)
    }
  }, [])

  const setFirstView = () => {
    const value = "false"
    const expirationYears = 1 // 1 year
    const expirationMS = expirationYears * 365 * 24 * 60 * 60 * 1000 // convert to milisecond
    const expirationTime = new Date().getTime() + expirationMS
    localStorage.setItem(localStorageKey, JSON.stringify({ value, expirationTime }))
  }

  const isFirstView = () => {
    const storedValue = localStorage.getItem(localStorageKey)
    let result = false
    if (storedValue) {
      const { expirationTime } = JSON.parse(storedValue)
      const currentTime = new Date().getTime()

      if (currentTime <= expirationTime) {
        result = false
      } else {
        result = true
        setFirstView()
      }
    } else {
      result = true
      setFirstView()
    }

    return result
  }

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {isOpen && (
        <div>
          <div className="model-overlay" />
          <div className="model">
            <style>{popupStyles}</style>
            <div className="model-dialog">
              <div className="model-content">
                <div className="model-header">
                  <img className="img-responsive" src={`${baseUrl}assets/img/popup_banner.png`} alt="" />
                  <button className="popup-close" onClick={togglePopup}>
                    <img src={`${baseUrl}assets/img/close_model.png`} alt="" />
                  </button>
                </div>
                <div className="model-body">
                  <div className="block">
                    <div className="block-header">人気ガチゲームとは</div>
                    <div className="block-body">
                      <div className="row">
                        <div className="col">
                          <div className="col-inner">
                            <img className="img-responsive" src={`${baseUrl}assets/img/popup_icon1.png`} alt="" />
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-inner">
                            <img className="img-responsive" src={`${baseUrl}assets/img/popup_icon2.png`} alt="" />
                          </div>
                        </div>
                        <div className="col">
                          <div className="col-inner">
                            <img className="img-responsive" src={`${baseUrl}assets/img/popup_icon3.png`} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="btn" type="button">
                    <a href="https://awp.gesoten.com?utm_source=portal_top_popup&utm_medium=731&utm_campaign=easygame&utm_id=influx">
                      人気ガチゲームへ
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
