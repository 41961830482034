import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { Crosshead } from "~/components/Crosshead"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { mixin, url } from "~/libs/style"

type VideoType = {
  video_id: string
  is_horizontal: boolean
  game_title: string
  game_url: string
  banner_title: string
}

type ShortVideoType = {
  videos: Array<VideoType>
}

export const ShortVideo: React.FC<ShortVideoType> = ({ videos }) => {
  const [video, setVideo] = useState<VideoType | undefined>(undefined)
  const [videoIndex, setVideoIndex] = useState(0)
  const cookieExpirationPeriod = 7
  const videoOptions = {
    autoplay: 1,
    controls: 1,
    loop: 1,
    mute: 1
  }
  const { analyticsTrack } = useTrackingNew()

  useEffect(() => {
    // Load the YouTube API script
    const script = document.createElement("script")
    script.src = "https://www.youtube.com/iframe_api"
    document.head.appendChild(script)

    script.onload = () => {
      let videoIdsInCookie: string[] = JSON.parse(getCookie("savedVideoIds") || "[]")
      window.YT.ready(function () {
        let isPlay = false
        for (let i = 0; i < videos.length; i++) {
          const videoId = videos[i].video_id
          if (videos.length == 1) {
            isPlay = true
          } else {
            if (!videoIdsInCookie.includes(videoId)) {
              isPlay = true
            }
          }

          if (isPlay) {
            setVideo(videos[i])
            setVideoIndex(i)
            videoIdsInCookie.push(videoId)
            videoIdsInCookie = Array.from(new Set(videoIdsInCookie))
            setCookie("savedVideoIds", JSON.stringify(videoIdsInCookie), cookieExpirationPeriod)
            new window.YT.Player(`player-${videoId}`, {
              videoId,
              playerVars: videoOptions,
              events: {
                onStateChange: (event: YT.OnStateChangeEvent) => {
                  if (event.data === window.YT.PlayerState.ENDED && typeof event.target.seekTo === "function") {
                    event.target.seekTo(0, true)
                  }
                }
              }
            })
            break
          }
        } //end for
        if (!isPlay) {
          const videoId = videos[0].video_id
          setVideo(videos[0])
          setVideoIndex(0)
          videoIdsInCookie = [videoId]
          setCookie("savedVideoIds", JSON.stringify(videoIdsInCookie), cookieExpirationPeriod)
          new window.YT.Player(`player-${videoId}`, {
            videoId,
            playerVars: videoOptions,
            events: {
              onStateChange: (event: YT.OnStateChangeEvent) => {
                if (event.data === window.YT.PlayerState.ENDED && typeof event.target.seekTo === "function") {
                  event.target.seekTo(0, true)
                }
              }
            }
          })
        }
      })
    }
    return () => {
      // Clean up script when component unmounts
      document.head.removeChild(script)
    }
  }, [])

  const setCookie = (name: string, value: string, days: number) => {
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + days)
    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`
  }

  const getCookie = (name: string) => {
    const cookies = document.cookie.split("; ")
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=")
      if (cookieName === name) {
        return cookieValue
      }
    }
    return null
  }

  return (
    <>
      {video && (
        <React.Fragment>
          <Section>
            <Wrapper>
              <Crosshead>ピックアップ無料ゲーム</Crosshead>
              <VideoWrap>
                <Player id={`player-${video?.video_id}`} className={video.is_horizontal ? "" : "vertical"} />
              </VideoWrap>
              <VideoTitle>{video.game_title}</VideoTitle>
              <GameButton>
                <a
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                    analyticsTrack(
                      event,
                      "SiteTop_ピックアップ無料ゲーム",
                      `${prefixNumber(videoIndex + 1)}個目`,
                      video.game_url
                    )
                  }
                  href={video.game_url}
                >
                  {video.banner_title}
                </a>
              </GameButton>
            </Wrapper>
          </Section>
        </React.Fragment>
      )}
    </>
  )
}

const Section = styled.section`
  margin: 0px 0;
  h2 {
    background: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: 23.2px;
    color: #3c3c3c;
    padding: 24px 10px 0px;
  }
`

const Wrapper = styled.div`
  ${mixin.maxWidth};
  margin: 0 auto;
  padding-bottom: 20px;
  margin-bottom: 8px;
  background: #ffffff;
  ${mixin.shadow}
`

const VideoWrap = styled.div`
  padding-top: 20px;
  max-width: 311px;
  margin: 0 auto;
`

const Player = styled.div`
  width: 311px;
  height: 544px;
  max-width: 100%;
`

const VideoTitle = styled.h3`
  font-family: Noto Sans JP;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 16px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
`

const GameButton = styled.div`
  text-align: center;
  a {
    background: #34b2d9;
    color: #ffffff !important;
    display: inline-block;
    width: 280px;
    max-width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    position: relative;
    &:before {
      content: ${url("/assets/img/right_arrow.svg")};
      position: absolute;
      right: 16px;
      top: 0;
    }
  }
`
