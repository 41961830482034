import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory, addPlayHistoryFreePage } from "~/libs/storage"
import { mixin, url } from "~/libs/style"

export type PropsType = {
  configDomain: { [key: string]: string }
  banner: Array<{
    app_id: number
    url: string
    banner: string
    title?: string
    analytics: {
      category: string
      label: string
    }
    isPaid: boolean
  }>
  className?: string // Add className property
  isPaid?: boolean
  title?: string
}

export const Banner = React.forwardRef(({ banner, configDomain, className, isPaid, title }: PropsType, ref) => {
  const { analyticsTrack } = useTrackingNew()
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    mobileFirst: true,
    dots: true
  }
  return (
    <Wrapper className={`${className} ${isPaid ? "paid-banner" : ""}`}>
      {title && (
        <Title>
          <CrossheadText>{title}</CrossheadText>
        </Title>
      )}
      <Slider {...settings}>
        {banner.slice(0, 7).map((game, index) => (
          <div key={index}>
            <Link
              href={game.url}
              className={game.isPaid ? "cambanner" : "topbanner-f"}
              data-gtm-bnum={` ${prefixNumber(index + 1)}個目 `}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                analyticsTrack(
                  event,
                  isPaid ? "SiteTop_キャンペーン" : game.analytics.category,
                  `${prefixNumber(index + 1)}個目`,
                  isPaid ? `${game.title}：${game.url}` : game.analytics.label
                )
                if (game.isPaid) {
                  addPlayHistory(game.app_id)
                } else {
                  addPlayHistoryFreePage(game.app_id, configDomain.free, ref)
                }
              }}
            >
              <Img src={game.banner} />
            </Link>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
})
Banner.displayName = "Banner"

const Wrapper = styled.section`
  background: #ffffff;
  margin: 0 auto;
  ${mixin.maxWidth}
  img {
    border-radius: 6px;
  }
  .slick-arrow {
    z-index: 1;
    width: 22px;
    height: 22px;
    top: calc(50% - 12px);
  }
  .slick-next {
    right: 5px;
    &::before {
      content: ${url("/assets/img/renew/slick_next_arrow.svg")};
    }
  }
  .slick-prev {
    left: 5px;
    &::before {
      content: ${url("/assets/img/renew/slick_prev_arrow.svg")};
    }
  }
  &.paid-banner {
    .slick-track {
      img {
        box-shadow: 0px 2px 2px 0px #00000026;
      }
    }
    .slick-dots {
      button:before {
        color: #fff;
        opacity: 1;
      }
    }
  }
  .slick-dots {
    position: initial;
    button:before {
      font-size: 7px;
    }
  }
  .slick-active {
    button:before {
      color: orange !important;
    }
  }
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 23.2px;
  color: #3c3c3c;
  margin-bottom: 8px;
`

const CrossheadText = styled.span`
  ${mixin.maxWidth}
  display: block;
  margin: 0 auto;
`

const Img = styled.img`
  max-width: 100%;
`
const Link = styled.a`
  position: relative;
  display: block;
`
