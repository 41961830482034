import { env } from "~/config"
import { adjustImgixParam, buildAssetsPath } from "~/libs/image"
import { BannersFreeSchema, BannersPaidSchema } from "~/repository/BannersRepository"
const isLocal = env === "local"
const buildBannerPath = buildAssetsPath("banner")
const adjustBannerParam = adjustImgixParam("banner")
type banner = {
  app_id: number
  url: string
  banner: string
  analytics: {
    category: string
    label: string
  }
  isPaid: boolean
}
type configDomain = { [key: string]: string }
export class BannersTopPresenter {
  static transform(
    bannersFree: BannersFreeSchema,
    bannersPaid: BannersPaidSchema,
    configDomain: configDomain
  ): banner[] {
    const top_banners = bannersPaid.contents.reduce<banner[]>((result, content) => {
      if (content.game) {
        const { game } = content
        const analyticsLabel = `${game.app_name}：${game.app_url}`
        const image = !game.image.url
          ? ""
          : isLocal
          ? adjustBannerParam(game.image.url)
          : buildBannerPath(game.id, "paid")
        result.push({
          app_id: game.app_id ?? 0,
          url: game.app_url,
          banner: image,
          analytics: {
            category: "SiteTop_有料ゲーム・特集バナー",
            label: analyticsLabel
          },
          isPaid: true
        })
      }
      return result
    }, [])

    bannersFree.contents.forEach((content, index: number) => {
      const { game } = content
      const image = !game.banner.url ? "" : isLocal ? adjustBannerParam(game.banner.url) : buildBannerPath(game.gameId)
      const gameUrl = game.medibaHostingFlag ? configDomain.top.slice(0, -1) + game.portalUrl : game.portalUrl
      const banner = {
        url: gameUrl,
        app_id: game.gameId,
        banner: image,
        title: game.title,
        analytics: {
          category: "SiteTop_無料ゲームバナー",
          label: [game.gameId, game.title, gameUrl].join("：")
        },
        isPaid: false
      }

      if (index === 0) {
        top_banners.splice(1, 0, banner)
      } else {
        top_banners.push(banner)
      }
    })
    return top_banners
  }
}
