import { distribution, env } from "~/config"
import { adjustImgixParam, buildAssetsPath } from "~/libs/image"

import { Game, GameBanner, GameBannerTransform } from "./../repository/GamesRepository"

const buildBannerPath = buildAssetsPath("banner")
const buildThumbnailPath = buildAssetsPath("thumbnail")

export class GamesPresenter {
  static transform(games: Game[]) {
    return games.map((game) => {
      const title = game.app_name ? game.app_name : game.title
      let prefixAssetsPath = ""
      if (distribution === "paid") {
        prefixAssetsPath = "/gachi"
      }
      // prefixAssetsPath

      let imageUrl = ""
      if ("undefined" !== typeof game.img_url) {
        imageUrl = game.img_url
      } else {
        imageUrl = game.image.url
      }
      const gameId = game.app_id
      return {
        gameId: gameId,
        title: title,
        analyticsLabel: "",
        text: game.description,
        url: game.app_url ? game.app_url : game.url,
        image: game.image ? game.image : { url: imageUrl },
        banner: buildBannerPath(gameId, "paid", prefixAssetsPath),
        thumbnail: buildThumbnailPath(gameId, "paid", prefixAssetsPath),
        genres: 0
      }
    })
  }
  static transformBannerToGame(games: GameBannerTransform[]) {
    return games.map((game) => {
      // prefixAssetsPath
      const gameId = game.app_id
      return {
        gameId: gameId,
        title: game.title,
        text: game.text,
        url: game.url,
        image: {
          url: game.thumbnail
        },
        banner: game.banner,
        thumbnail: game.thumbnail,
        genres: 0
      }
    })
  }
  /* transform old game  */
  static transformBanner(games: GameBanner[]) {
    const adjustBannerParam = adjustImgixParam("banner")
    // const adjustThumbnailParam = adjustImgixParam("thumbnail")
    const isLocal = env === "local"

    return games.map((game) => {
      const title = game.app_name ? game.app_name : game.title
      let prefixAssetsPath = ""
      if (distribution === "paid") {
        prefixAssetsPath = "/gachi"
      }
      const bannerUrl = game.image.url
      let imageUrl = ""
      if ("undefined" !== typeof game.img_url) {
        imageUrl = game.img_url
      }
      if ("undefined" !== typeof game.thumbnail) {
        imageUrl = game.thumbnail
      }

      const gameId = game.id
      let app_id = 0
      if ("undefined" !== typeof game.app_id) {
        app_id = game.app_id
      }
      return {
        gameId: gameId,
        title: title,
        analyticsLabel: "",
        app_id: app_id.toString(),
        text: "",
        url: game.app_url ? game.app_url : game.url,
        image: game.image ? game.image : { url: bannerUrl },
        banner: isLocal ? adjustBannerParam(bannerUrl) : buildBannerPath(gameId, "paid", prefixAssetsPath),
        thumbnail: imageUrl,
        genres: 0
      }
    })
  }
}
