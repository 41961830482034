import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory, addPlayHistoryFreePage } from "~/libs/storage"
import { mixin } from "~/libs/style"

export type PropsType = {
  configDomain: { [key: string]: string }
  banner: Array<{
    app_id: number
    url: string
    banner: string
    analytics: {
      category: string
      label: string
    }
    isPaid: boolean
  }>
}

export const Banner = React.forwardRef(({ banner, configDomain }: PropsType, ref) => {
  const { analyticsTrack } = useTrackingNew()
  const settings = {
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    mobileFirst: true
  }
  return (
    <Wrapper>
      <Slider {...settings}>
        {banner.slice(0, 10).map((game, index) => (
          <div key={index}>
            <Link
              href={game.url}
              className={game.isPaid ? "topbanner-p" : "topbanner-f"}
              data-gtm-bnum={` ${prefixNumber(index + 1)}個目 `}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                analyticsTrack(event, game.analytics.category, `${prefixNumber(index + 1)}個目`, game.analytics.label)
                if (game.isPaid) {
                  addPlayHistory(game.app_id)
                } else {
                  addPlayHistoryFreePage(game.app_id, configDomain.free, ref)
                }
              }}
            >
              <Img src={game.banner} />
            </Link>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
})
Banner.displayName = "Banner"

const Wrapper = styled.section`
  margin: 0 auto;
  ${mixin.maxWidth}
`
const Img = styled.img`
  max-width: 100%;
`
const Link = styled.a`
  position: relative;
  display: block;
`
