import React from "react"
import styled from "styled-components"

import { distribution } from "~/config"
import { useTrackingNew } from "~/contexts/Tracking"
import { color, mixin } from "~/libs/style"

type ButtonProps = {
  url: string
  isActive: boolean
  analyticsAction: string
}

type NavigationButtonProps = {
  configDomain: {
    top: string
    paid: string
    free: string
  }
}

export const NavigationGameButton: React.FC<NavigationButtonProps> = (props) => {
  const { configDomain } = props
  return (
    <Wrapper>
      <Nav>
        <GameButton isActive={distribution === "paid"} url={configDomain.paid} analyticsAction="人気ガチゲーム">
          <span>人気ガチゲーム</span>
          <span>（基本無料）</span>
        </GameButton>
        <GameButton isActive={distribution === "free"} url={configDomain.free} analyticsAction="簡単ゆるゲーム">
          <span>簡単ゆるゲーム</span>
          <span>（無料）</span>
        </GameButton>
      </Nav>
    </Wrapper>
  )
}

const GameButton: React.FC<ButtonProps> = (props) => {
  const { analyticsTrack } = useTrackingNew()
  return (
    <Item className={props.isActive ? "active" : ""}>
      {props.isActive ? (
        <Text>{props.children}</Text>
      ) : (
        <Link
          href={props.url}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
            analyticsTrack(event, "SiteTop_タブ", props.analyticsAction, "")
          }
        >
          {props.children}
        </Link>
      )}
    </Item>
  )
}

const Wrapper = styled.section`
  padding: 20px 10px;
  background-color: ${color.white};
  margin: 0 auto;
  ${mixin.maxWidth}
`

const Nav = styled.ul`
  display: flex;
  justify-content: center;
  align-item: center;
  border-radius: 5px;
  box-shadow: 0px 2px 0px #bfbfbf;
  background: ${color.orange};
`

const Item = styled.li`
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  border: 1px solid rgba(60, 60, 60, 0.4);

  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
  a {
    padding: 5px 0px;
    line-height: 1.5;
    color: ${color.white} !important;
    text-decoration: none;
    span:first-child {
      text-decoration: underline;
    }
    span:last-child {
      display: block;
      text-decoration: none;
      with: 100%;
    }
  }
  &.active {
    background: ${color.white};
  }
`

const Link = styled.a`
  display: block;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
`

const Text = styled.span`
  font-size: 13px;
  font-weight: 600;
`
