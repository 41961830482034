import { distribution, env } from "~/config"
import { adjustImgixParam, buildAssetsPath, bustCache } from "~/libs/image"
import { Game } from "~/repository/GamesRepository"

const isLocal = env === "local"
const buildBannerPath = buildAssetsPath("banner")
const buildThumbnailPath = buildAssetsPath("thumbnail")
const adjustBannerParam = adjustImgixParam("banner")
const adjustThumbnailParam = adjustImgixParam("thumbnail")

export class GamesPresenter {
  static transform(games: Game[]) {
    return games.map((game) => {
      const analyticsLabel = game.analyticsLabel || [game.gameId, game.title, game.genres[0].code].join("：")
      const thumbnail = bustCache(buildThumbnailPath(game.gameId))
      const distUrl = `${distribution}Url` as "appUrl" | "portalUrl"
      let gameUrl = ""
      if (typeof game[distUrl] === "undefined") {
        gameUrl = game["portalUrl"]
      }
      return {
        gameId: game.gameId,
        analyticsLabel,
        title: game.title,
        text: game.text,
        url: gameUrl,
        banner: !game.banner ? "" : isLocal ? adjustBannerParam(game.banner.url) : buildBannerPath(game.gameId),
        thumbnail: isLocal ? adjustThumbnailParam(game.thumbnail.url) : thumbnail,
        genres: game.genres,
        medibaHostingFlag: game.medibaHostingFlag
      }
    })
  }
}
