import React, { useState } from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { mixin, url } from "~/libs/style"
interface AccordionItem {
  title: string
  content: string
}

interface AccordionProps {
  items: AccordionItem[]
}

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const { analyticsTrack } = useTrackingNew()
  const onItemClick = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <Wrapper>
      <Container>
        <ListItem>
          {items.map((item, index) => (
            <div key={index}>
              <div
                className={`accordion-item ${index === activeIndex ? "active" : ""}`}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  onItemClick(index)
                  analyticsTrack(event, "SiteTop_ゲソてん紹介", "イージーゲーム powered by ゲソてんとは？")
                }}
              >
                <div className="accordion-title">
                  <span>{item.title}</span>
                </div>
                {index === activeIndex && <div className="accordion-content">{item.content}</div>}
              </div>
            </div>
          ))}
        </ListItem>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section``

const Container = styled.div`
  ${mixin.maxWidth}
  margin: 0 auto;
  padding: 0 12px;
`

const ListItem = styled.div`
  .accordion-item {
    border: 2px solid transparent;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px #00000026;
    background: #ffffff;
    padding: 12px 16px;
    .accordion-title {
      position: relative;
      padding-right: 20px;
      span {
        font-family: Noto Sans JP;
        font-size: 13px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.05em;
      }
      &:after {
        content: ${url("/assets/img/renew/vector_down.svg")};
        position: absolute;
        right: 4px;
      }
    }
    .accordion-content {
      font-family: Noto Sans JP;
      font-size: 13px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0.05em;
      text-align: left;
    }
    &.active {
      .accordion-title {
        margin-bottom: 23px;
      }
    }
  }
`
