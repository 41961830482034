import React from "react"
import styled from "styled-components"

import { Card } from "~/components/Card"
import { HeadLink } from "~/components/HeadLink"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory } from "~/libs/storage"
import { mixin, thumbnailSizePaid } from "~/libs/style"

type GameCatalogProps = {
  gameCatalogs: Array<{
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    banner: string
    thumbnail: string
    image: {
      url: string
    }
  }>
}

export const PaidGameCatalog: React.FC<GameCatalogProps> = ({ gameCatalogs }) => {
  const { analyticsTrack } = useTrackingNew()
  const categoryTracking = "有料Top_有料ゲーム一覧"
  return (
    <>
      <HeadLink title="人気ガチゲーム一覧" />
      <Wrapper>
        <Items>
          {gameCatalogs.map((gameCatalog, index) => (
            <li key={index}>
              <a
                href={gameCatalog.url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  analyticsTrack(
                    event,
                    categoryTracking,
                    `${prefixNumber(index + 1)}個目`,
                    `${gameCatalog.gameId}：${gameCatalog.title}`
                  )
                  addPlayHistory(gameCatalog.gameId)
                }}
              >
                <PaidCard imageURL={gameCatalog.image.url}>
                  <Text>
                    <Body>{gameCatalog.title}</Body>
                    <SubText>{gameCatalog.text}</SubText>
                    <Button>人気ガチ</Button>
                  </Text>
                </PaidCard>
              </a>
            </li>
          ))}
        </Items>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  margin: 0;
  padding: 0px 7px;
`

const Items = styled.ul`
  ${mixin.maxWidth}
  padding: 5px 0px;
  margin: 0 auto;
  background-color: white;
  li {
    border-bottom: thin solid #e9e9e9;
    &:last-child {
      border-bottom: none;
    }
  }
`

const Text = styled.div`
  flex: 1;
  overflow: hidden;
  padding-left: 10px;
  font-size: 16px;
  line-height: 1.5;
`

const Body = styled.p`
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 145%;
`

const SubText = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
  font-size: 10px;
  line-height: 145%;
`

const PaidCard = styled(Card)`
  &::before {
    background: none;
    border: none;
  }
  img {
    border-radius: 10px;
    width: ${thumbnailSizePaid}px;
    height: ${thumbnailSizePaid}px;
  }
`
const Button = styled.button`
  background: #e9e9e9;
  border-radius: 2px;
  border: none;
  font-size: 8px;
  padding: 2px 10px;
`
