import React from "react"
import styled from "styled-components"

import { color } from "~/libs/style"

const fluctSnipet = `(function() {
  var unitId = 'fluct-unit-1000228030';
  // infoマークを追加する処理
  function addInfoMark(t) {
    if (!t) {
      return;
    }
    var button = document.createElement("a");
    button.href = "https://corp.fluct.jp/privacy/optout/"
    var img = document.createElement("img");
    img.src = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjcuOTY0IDI3Ljk2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjcuOTY0IDI3Ljk2NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImMxMjZfZXhjbGFtYXRpb24iPg0KCQk8cGF0aCBkPSJNMTMuOTgyLDAuMDAxQzYuMjU5LDAuMDAxLDAsNi4yNTksMCwxMy45ODRjMCw3LjcyMSw2LjI1OSwxMy45OCwxMy45ODIsMTMuOThjNy43MjIsMCwxMy45ODItNi4yNiwxMy45ODItMTMuOTgNCgkJCUMyNy45NjUsNi4yNTksMjEuNzA0LDAuMDAxLDEzLjk4MiwwLjAwMXogTTEzLjk4MiwyNi41MzNjLTYuOTMzLDAtMTIuNTUxLTUuNjE5LTEyLjU1MS0xMi41NDkNCgkJCWMwLTYuOTM1LDUuNjE4LTEyLjU1MiwxMi41NTEtMTIuNTUyYzYuOTMxLDAsMTIuNTUsNS42MTcsMTIuNTUsMTIuNTUyQzI2LjUzMiwyMC45MTQsMjAuOTEzLDI2LjUzMywxMy45ODIsMjYuNTMzeiIvPg0KCQk8cmVjdCB4PSIxMi4wMDQiIHk9IjEwLjU1MSIgd2lkdGg9IjMuOTMxIiBoZWlnaHQ9IjEyLjY0OSIvPg0KCQk8cGF0aCBkPSJNMTMuOTk1LDUuMDcyYy0xLjI2NSwwLTIuMDk1LDAuODUyLTIuMDk1LDEuOTYzYzAsMS4wODcsMC44MDIsMS45NjQsMi4wNDQsMS45NjRjMS4zMTcsMCwyLjExOS0wLjg3NywyLjExOS0xLjk2NA0KCQkJQzE2LjAzNyw1LjkyNCwxNS4yNjIsNS4wNzIsMTMuOTk1LDUuMDcyeiIvPg0KCTwvZz4NCgk8ZyBpZD0iQ2FwYV8xXzEzOV8iPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K";
    img.style.position = "absolute";
    img.style.left = "0px";
    img.style.top = "-18px";
    img.style.width = "14px";
    img.style.height = "14px";
    button.appendChild(img)
    t.appendChild(button)
  }
  var target = document.getElementsByClassName("fluct-unit-1000228030")[0];
  var observer = new MutationObserver(function (records) {
    var self = this;
    records.forEach(function (r) {
      r.addedNodes.forEach(function (n) {
        addInfoMark(target);
        self.disconnect();
      });
    });
  });
  observer.observe(target, {
    childList: true,
    subtree: true,
  });
})();`

export const Fluct: React.FC = () => {
  return (
    <Wrapper>
      <style>
        {`
          .fluct-unit-1000228030 {
            left: 20px !important;
            bottom: 70px !important;
            height: 1px;
          }
        `}
      </style>
      <div className="fluct-unit-1000228030"></div>
      <script type="text/javascript" src="https://pdn.adingo.jp/p.js" async></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                var fluctAdScript = fluctAdScript || {};
                fluctAdScript.cmd = fluctAdScript.cmd || [];
                fluctAdScript.cmd.push(function (cmd) {
                  cmd.loadByGroup("1000134290");
                  cmd.display(".fluct-unit-1000228030", "1000228030");
                });
              `
        }}
      />
      <script dangerouslySetInnerHTML={{ __html: fluctSnipet }} />
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding-top: 20px;
  background-color: ${color.whiteSmoke};
`
