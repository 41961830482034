import React from "react"
import styled from "styled-components"

import { distribution } from "~/config"
import { useTrackingNew } from "~/contexts/Tracking"
import { color, mixin } from "~/libs/style"

type ButtonProps = {
  url: string
  isActive: boolean
}

type NavigationButtonProps = {
  configDomain: {
    top: string
    paid: string
    free: string
  }
}

export const NavigationGameButton: React.FC<NavigationButtonProps> = (props) => {
  const { configDomain } = props
  return (
    <Wrapper>
      <Nav>
        <GameButton isActive={distribution === "paid"} url={configDomain.paid}>
          人気ガチゲーム
        </GameButton>
        <GameButton isActive={distribution === "free"} url={configDomain.free}>
          簡単ゆるゲーム
        </GameButton>
      </Nav>
    </Wrapper>
  )
}

const GameButton: React.FC<ButtonProps> = (props) => {
  const { analyticsTrack } = useTrackingNew()
  const analyticsAction = String(props.children)
  const categoryTracking = "有料Top_タブ"
  return (
    <Item className={props.isActive ? "active" : ""}>
      {props.isActive ? (
        <Text
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
            analyticsTrack(event, categoryTracking, analyticsAction)
          }
        >
          {props.children}
        </Text>
      ) : (
        <Link
          href={props.url}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
            analyticsTrack(event, categoryTracking, analyticsAction)
          }
        >
          {props.children}
        </Link>
      )}
    </Item>
  )
}

const Wrapper = styled.section`
  padding: 20px 10px;
  background-color: ${color.white};
  margin: 0 auto;
  ${mixin.maxWidth}
`

const Nav = styled.ul`
  display: flex;
  justify-content: center;
  align-item: center;
  border-radius: 5px;
  box-shadow: 0px 2px 0px #bfbfbf;
  background: ${color.orange};
`

const Item = styled.li`
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  border: 1px solid rgba(60, 60, 60, 0.4);
  height: 39px;
  line-height: 39px;
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
  a {
    color: ${color.white} !important;
  }
  &.active {
    background: ${color.white};
  }
`

const Link = styled.a`
  display: block;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
`

const Text = styled.span`
  font-size: 13px;
  font-weight: 600;
`
