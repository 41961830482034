import React from "react"
import styled from "styled-components"

import { color, mixin } from "~/libs/style"

export const NotePortal: React.FC = () => {
  return (
    <Wrapper>
      <Items>
        <Title>推奨環境</Title>
        <Item>Android 5以上（Chrome 51以上）</Item>
        <Item>iOS 12.1.3以上（Safari 12以上）</Item>
        <Br />
        <Item>※iOSではゲームの履歴が正常に表示されない場合があります。</Item>
        <Title>注意事項</Title>
        <Item>・不安定な通信環境では正常に遊べない場合があります。</Item>
        <Item>・端末、OS、ブラウザのバージョンによっては正常に遊べない場合があります。</Item>
        <Item>・プライベートブラウザモードでは正常に遊べない場合があります。</Item>
        <Item>・広告ブロックを設定すると正常に遊べない場合があります。</Item>
        <Item>・ブラウザのCookieやキャッシュをクリアすると、得点などの記録が消去されます。</Item>
        <Item>
          ・iOS 13.4以上の環境では7日以上の遊ばない期間が発生すると、得点などの記録が消去される場合があります。
        </Item>
        <Item>・本サービスは日本国内専用です。</Item>
        <Item>・This service is for Japan only.</Item>
      </Items>
    </Wrapper>
  )
}

export const NoteApp: React.FC = () => {
  return (
    <Wrapper>
      <Items>
        <Title>推奨環境</Title>
        <Item>
          Android：4.4以上（Chrome56以上）
          <br />
          <Sup>
            ※Chromeのアップデートについては<Link href="https://support.google.com/chrome/answer/95414">こちら</Link>。
          </Sup>
        </Item>
        <Title>注意事項</Title>
        <Item>・端末やOSのバージョンによっては正常に遊べない場合があります。</Item>
        <Item>
          ・AndroidにおいてChromeのバージョンによっては正常に遊べない場合があります。その場合最新版へのアップデートをお試しください。
          <br />
          <Sup>
            ※Chromeのアップデートについては<Link href="https://support.google.com/chrome/answer/95414">こちら</Link>。
          </Sup>
        </Item>
        <Item>
          ・AndroidにおいてChrome以外のブラウザをお使いの場合、一部のゲームが正常に遊べない場合があります。
          <br />
          その場合標準のブラウザアプリをChromeにご変更の上お試しください。
        </Item>
        <Item>・広告ブロックを設定すると正常に遊べない場合があります。</Item>
        <Item>・ご使用のアプリやブラウザのデータやキャッシュをクリアしますと、過去のデータがリセットされます。</Item>
      </Items>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px 10px;
  background-color: ${color.whiteSmoke};
`

const Items = styled.dl`
  ${mixin.maxWidth}
  margin: 0 auto;
`

const Title = styled.dt`
  height: 20px;
  padding-left: 6px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: ${color.dimGray};
  border-left: 3px solid ${color.dimGray};
  &:first-child {
    margin-top: 0;
  }
`
const Text = styled.dd`
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.2;
`

const Item = styled(Text)`
  padding-left: 1em;
  text-indent: -1em;
`

const Link = styled.a`
  &:link,
  &:hover,
  &:active {
    color: ${color.blue};
  }
  &:visited {
    color: ${color.purple};
  }
`

const Sup = styled.span`
  line-height: 2;
`

const Br = styled.br``
