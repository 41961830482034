import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { Crosshead } from "~/components/Crosshead"
import { HorizontalItems } from "~/components/HorizontalItems"
import { Thumbnail } from "~/components/Thumbnail"
import { useTracking } from "~/contexts/Tracking"
import { migrateHistories } from "~/libs/migration"
import { addPlayHistory, getPlayHistories, setPlayHistories } from "~/libs/storage"
import { thumbnailSize } from "~/libs/style"

type GamesProps = {
  games: Array<{
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    banner: string
    thumbnail: string
    genres: Array<{
      code: string
      name: string
    }>
  }>
}

const useHistories = (games: GamesProps["games"]) => {
  const [historyGames, setHistoryGames] = useState<GamesProps["games"]>([])

  useEffect(() => {
    migrateHistories()

    const gameIds = getPlayHistories()
    const newHistoryGames = gameIds
      .map((gameId) => games.find((game) => game.gameId === gameId))
      .filter((game): game is NonNullable<typeof game> => game !== undefined)

    setPlayHistories(newHistoryGames.map((historyGame) => historyGame.gameId))

    if (newHistoryGames.length) {
      setHistoryGames(newHistoryGames)
    }
  }, [])

  return historyGames
}

export const History: React.FC<GamesProps> = ({ games }) => {
  const historyGames = useHistories(games)
  const { analyticsTrack } = useTracking()

  if (!historyGames.length) {
    return null
  }

  return (
    <Wrapper>
      <Crosshead>最近プレイしたゲーム</Crosshead>
      <HorizontalItems>
        {historyGames.map((game, index) => (
          <Item key={index}>
            <HorizontalLink
              key={index}
              href={game.url}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                analyticsTrack(event, "最近プレイしたゲーム", `${game.analyticsLabel}：${index + 1}`)
                addPlayHistory(game.gameId)
              }}
            >
              <Thumbnail src={game.thumbnail} />
              <GameTitle>{game.title}</GameTitle>
            </HorizontalLink>
          </Item>
        ))}
      </HorizontalItems>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin: 20px 0;
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
  width: ${thumbnailSize}px;
  padding: 5px 0;
`

const Item = styled.li`
  a {
    margin-right: 5px;
  }
  &:last-child a {
    margin-right: 10px;
  }
`

const GameTitle = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  margin-top: 5px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  text-overflow: clip;
  white-space: normal;
`
