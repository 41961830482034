import React from "react"
import styled from "styled-components"

import { color, thumbnailSize } from "~/libs/style"

type ThumbnailProps = {
  src: string
  className?: string
}

const imageOnLoadHandler = (e: React.SyntheticEvent<HTMLImageElement>) => {
  e.currentTarget.style.backgroundColor = color.white // 背景透過サムネイルのために変更
}

export const Thumbnail: React.FC<ThumbnailProps> = ({ src, className }) => {
  return (
    <Img
      className={className}
      src={src}
      width={thumbnailSize}
      height={thumbnailSize}
      alt=""
      loading="lazy"
      onLoad={imageOnLoadHandler}
    />
  )
}

const Img = styled.img`
  position: relative;
`
