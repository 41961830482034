type GameIdMap = {
  [key: string]: number
}

export const gameIdMap: GameIdMap = {
  "success-corp.jp_su-15-nikakudori": 106,
  "wkb.jp_hypersnake": 105,
  "sycasualgames.com_donutsparty": 104,
  "success-corp.jp_su-05-mahjong": 103,
  "success-corp.jp_su-104-solitairedouble": 102,
  "dcontech.net_16pazzle": 101,
  "success-corp.jp_su-06-spider": 100,
  "success-corp.jp_su-28-battleships": 99,
  "dcontech.net_kyoufu_drive": 98,
  "dcontech.net_2taku_de_Q": 97,
  "success-corp.jp_su-47-scorpion": 96,
  "success-corp.jp_su-03-gourmetdiver": 95,
  "success-corp.jp_su-29-warshipgame": 94,
  "sycasualgames.com_daisogen": 93,
  "success-corp.jp_su-51-1010lines": 92,
  "sycasualgames.com_deppcircus": 91,
  "sycasualgames.com_tomokumapon": 90,
  "success-corp.jp_su-53-kemonomono": 89,
  "success-corp.jp_su-55-pipegame": 88,
  "wkb.jp_sealdeal": 87,
  "bigb.jp_marriage_bowl": 86,
  "success-corp.jp_su-68-sukima": 85,
  "success-corp.jp_su-91-solitairepoker": 84,
  "dcontech.net_weightlifting": 83,
  "sycasualgames.com_jumpon": 82,
  "wkb.jp_pirates": 81,
  "dcontech.net_hitsuji": 80,
  "wkb.jp_unblockthat": 79,
  "bigb.jp_afro_hammer": 78,
  "success-corp.jp_su-95-make10": 77,
  "dcontech.net_poker": 76,
  "sycasualgames.com_soshokuswing": 75,
  "wkb.jp_tastyjewel": 74,
  "wkb.jp_jumpjustin": 73,
  "sagames.net_casino_bowling": 72,
  "bigb.jp_dream_basket": 71,
  "sycasualgames.com_heartdegechu": 70,
  "wkb.jp_ultimate7x7": 69,
  "bigb.jp_asobooya": 68,
  "wkb.jp_jewelpop": 67,
  "dcontech.net_daidasso": 66,
  "dcontech.net_minesweeper": 65,
  "bigb.jp_dokidoki": 64,
  "wkb.jp_legend2048": 63,
  "wkb.jp_sheepstacking": 62,
  "wkb.jp_flatjewelslevels": 61,
  "wkb.jp_catspranks": 60,
  "0033": 59,
  "sycasualgames.com_usaodrop": 58,
  "wkb.jp_solitairegrande": 57,
  "sycasualgames.com_pitopotopuzzle": 56,
  "wkb.jp_roadfury": 55,
  "wkb.jp_shards": 54,
  "dcontech.net_flower": 53,
  "sycasualgames.com_pitapuzz": 52,
  "wkb.jp_soccerhero": 51,
  "sycasualgames.com_makeupqueen": 50,
  "wkb.jp_jewelpets": 49,
  "dcontech.net_daifugo": 48,
  "wkb.jp_knifesmash": 47,
  "wkb.jp_farming10x10": 46,
  "dcontech.net_gomokunarabe": 45,
  "wkb.jp_furiousspeed": 44,
  "wkb.jp_dunkbrush": 43,
  "sycasualgames.com_lany_spaceconques": 42,
  "wkb.jp_bridgeofdoom": 41,
  "dcontech.net_fishing": 40,
  "wkb.jp_dontred": 39,
  "sycasualgames.com_chainsolitaire": 38,
  "wkb.jp_get10": 37,
  "wkb.jp_elevenx": 36,
  "dcontech.net_nikakutori": 35,
  "wkb.jp_balls99": 34,
  "sycasualgames.com_twomix": 33,
  "0023": 32,
  "0011": 31,
  "0019": 30,
  "0016": 29,
  "0020": 28,
  "0028": 27,
  "0021": 26,
  "0015": 25,
  "0027": 24,
  "0032": 23,
  "0024": 22,
  "0031": 21,
  "0025": 20,
  "0030": 19,
  "0014": 18,
  "0022": 17,
  "0012": 16,
  "0017": 15,
  "0013": 14,
  "0018": 13,
  "0029": 12,
  "0026": 11,
  "0005": 10,
  "0007": 9,
  "0008": 8,
  "0010": 7,
  "0001": 6,
  "0004": 5,
  "0003": 4,
  "0009": 3,
  "0002": 2,
  "0006": 1
}

const newHistoryKey = "habits_play_history"
const oldCommonkey = "habits_game_common"
const oldHistoryKey = "play_history"

export function migrateHistories() {
  try {
    const json = window.localStorage.getItem(oldCommonkey)
    if (!json) {
      return
    }
    const common: Record<typeof oldHistoryKey, string[]> = JSON.parse(json)
    if (!common) {
      return
    }

    if (oldHistoryKey in common) {
      const oldGameIds = common.play_history
      const newGameIds = oldGameIds.map((oldGameId) => gameIdMap[oldGameId])
      window.localStorage.setItem(newHistoryKey, JSON.stringify(newGameIds))
      console.log("migrate:", JSON.stringify(oldGameIds), "->", JSON.stringify(newGameIds))
    }

    window.localStorage.removeItem(oldCommonkey)
  } catch (e) {
    console.error(e)
  }
}
