import React from "react"
import styled from "styled-components"

import { Crosshead } from "~/components/Crosshead"
import { HorizontalItems } from "~/components/HorizontalItems"
import { RankingProps } from "~/components/Ranking"
import { Thumbnail } from "~/components/Thumbnail"
import { TopRankingLabel } from "~/components/TopRankingLabel"
import { useTracking } from "~/contexts/Tracking"
import { addPlayHistory } from "~/libs/storage"
import { color, thumbnailSize } from "~/libs/style"

type TopRankingProps = Pick<RankingProps, "ranking">

export const TopRanking: React.FC<TopRankingProps> = ({ ranking }) => {
  const { analyticsTrack } = useTracking()

  return (
    <Wrapper>
      <Crosshead>人気ゲームランキング</Crosshead>
      <HorizontalItems>
        {ranking.slice(0, 10).map((game, index) => (
          <Item key={index}>
            <LabelWrapper>
              <TopRankingLabel rank={index + 1} />
            </LabelWrapper>

            <HorizontalLink
              key={index}
              href={game.url}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                analyticsTrack(event, "ゲームランキング", game.analyticsLabel)
                addPlayHistory(game.gameId)
              }}
            >
              <Thumbnail src={game.thumbnail} />
              <GameTitle>{game.title}</GameTitle>
            </HorizontalLink>
          </Item>
        ))}
      </HorizontalItems>
      <MoreLink
        href="/ranking"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          analyticsTrack(event, "ゲームランキング", "1位〜20位までまとめてみる")
        }}
      >
        1位〜20位までまとめてみる
      </MoreLink>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin: 20px 0;
`

const Item = styled.li`
  a {
    margin-right: 5px;
  }
  &:last-child a {
    margin-right: 10px;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const MoreLink = styled.a`
  position: relative;
  display: block;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  &:link,
  &:hover,
  &:active,
  &:visited {
    color: ${color.blue};
  }
  &::after {
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 20px;
    content: "";
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    transform: translateY(-50%) rotate(-45deg);
  }
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
  width: ${thumbnailSize}px;
  padding: 5px 0;
`

const GameTitle = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 5px;
  overflow: hidden;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  text-overflow: clip;
  white-space: normal;
`
