import React from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { color, mixin } from "~/libs/style"

type NoticeProps = {
  noticesCatalogs?: Array<{
    id: string
    title: string
    url: string
  }>
}

export const NoticeCatalog: React.FC<NoticeProps> = ({ noticesCatalogs }) => {
  const { analyticsTrack } = useTrackingNew()
  const categoryTracking = "有料Top_お知らせ"

  if (typeof noticesCatalogs === "undefined" || noticesCatalogs.length < 1) {
    return null
  }
  return (
    <NoticeList>
      {noticesCatalogs.map((notice, index) => (
        <li key={index}>
          <Link
            href={notice.url}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
              analyticsTrack(event, categoryTracking, notice.title, notice.url)
            }
          >
            {notice.title}
          </Link>
        </li>
      ))}
    </NoticeList>
  )
}

const Link = styled.a`
  ${mixin.maxWidth}
  position: relative;
  box-sizing: border-box;
  display: block;
  padding: 10px 25px 10px 0;
  margin: 0 auto;
  font-size: 14px;
  border-bottom: 1px solid ${color.lightBlue};
  &::after {
    position: absolute;
    top: 53%;
    right: 10px;
    display: block;
    width: 7px;
    height: 7px;
    content: "";
    border-top: 1px solid ${color.steelGray};
    border-right: 1px solid ${color.steelGray};
    transform: rotate(45deg) translate(-50%, -50%);
  }
`

const NoticeList = styled.ul`
  position: relative;
  padding: 0 10px;
  margin: 5px 10px;
  line-height: 1.2;
  border: 2px solid ${color.lightBlue};
  border-radius: 6px;

  &::before,
  &::after {
    position: absolute;
    right: 72px;
    width: 0;
    height: 0;
    margin: auto;
    content: "";
  }
  &::before {
    top: -12px;
    border-color: transparent transparent ${color.lightBlue} transparent;
    border-style: solid;
    border-width: 0 8px 12px 8px;
  }
  &::after {
    top: -8px;
    right: 74px;
    z-index: 10;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 6px 9px 6px;
  }

  li:last-child > ${Link} {
    border: none;
  }
`
