import React from "react"
import styled from "styled-components"

import { useTrackingNew } from "~/contexts/Tracking"
import { color, mixin } from "~/libs/style"

type TypeProps = {
  title: string
  url?: string
  moreText?: string
  analyticsAction?: string
  analyticsCategory?: string
}

export const HeadLink: React.FC<TypeProps> = ({
  title,
  url,
  moreText,
  analyticsAction = "",
  analyticsCategory = ""
}) => {
  const { analyticsTrack } = useTrackingNew()
  return (
    <>
      <Crosshead>
        {title}
        {moreText ? (
          <MoreLink
            href={url}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              analyticsTrack(event, analyticsCategory, analyticsAction)
            }}
          >
            {moreText}
          </MoreLink>
        ) : (
          ""
        )}
      </Crosshead>
    </>
  )
}

export const Crosshead: React.FC = ({ children }) => {
  return (
    <CrossheadWrapper>
      <CrossheadText>{children}</CrossheadText>
    </CrossheadWrapper>
  )
}

const MoreLink = styled.a`
  position: absolute;
  display: block;
  font-size: 10px;
  line-height: 14.5px;
  text-align: center;
  top: 0;
  right: 7px;
  &:link,
  &:hover,
  &:active,
  &:visited {
    color: ${color.blue};
  }
  text-decoration-line: underline;
`
const CrossheadWrapper = styled.h2`
  padding: 10px 7px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20.3px;
  color: ${color.dimGray};
  background-color: ${color.whiteSmoke};
`
const CrossheadText = styled.span`
  ${mixin.maxWidth}
  display: block;
  margin: 0 auto;
  position: relative;
`
