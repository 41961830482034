import getConfig from "next/config"
import React from "react"

import { FreeApp, FreeAppProps, getFreeData } from "~/app/FreeApp"
import { getPaidData, PaidApp } from "~/app/PaidApp"
import { PortalApp } from "~/app/PortalApp"
import { getTopData, TopApp, TopAppProps } from "~/app/TopApp"
import { adIds, distribution, distributionTitle, env } from "~/config"
import { Fetcher } from "~/external/Fetcher"
import { useRepro } from "~/hooks/useRepro"
import { ConfigPresenter } from "~/presenter/ConfigPresenter"
import { GameCatalogsPresenter } from "~/presenter/GameCatalogsPresenter"
import { GamesPresenter } from "~/presenter/GamesPresenter"
import { NoticeCatalogsPresenter } from "~/presenter/NoticeCatalogsPresenter"
import { PickupsPresenter } from "~/presenter/PickupsPresenter"
import { RankingPresenter } from "~/presenter/RankingPresenter"
import { ConfigRepository } from "~/repository/ConfigRepository"
import { GameCatalogsRepository } from "~/repository/GameCatalogsRepository"
import { GamesRepository } from "~/repository/GamesRepository"
import { GenresRepository } from "~/repository/GenresRepository"
import { NoticeCatalogsRepository } from "~/repository/NoticeCatalogsRepository"
import { PickupsRepository } from "~/repository/PickupsRepository"
import { RankingRepository } from "~/repository/RankingRepository"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type NoUnion<Key> = [Key] extends [UnionToIntersection<Key>] ? Key : never

const Index = (props: NoUnion<TopAppProps | FreeAppProps>) => {
  useRepro(distribution)
  return (
    <>
      {(() => {
        switch (distribution) {
          case "free":
            return <FreeApp {...props} />
          case "paid":
            return <PaidApp {...props} />
          case "top":
            return <TopApp {...props} />
          case "portal":
            return <PortalApp {...props} />
          default:
            return <TopApp {...props} />
        }
      })()}
    </>
  )
}

export const getStaticProps = async () => {
  let propsData = null
  if (distribution === "portal") {
    propsData = await _getPortalData()
  }
  if (distribution === "top") {
    propsData = await _getTopData()
  }
  if (distribution === "paid") {
    propsData = await _getPaidData()
  }
  if (distribution === "free") {
    propsData = await _getFreeData()
  }
  return {
    props: propsData
  }
}
/*== Get Data for portal page */
const _getPortalData = async () => {
  const {
    serverRuntimeConfig: { cmsEndpoint, cmsApiKey }
  } = getConfig()
  const fetcher = new Fetcher({ cmsEndpoint, cmsApiKey })

  const genresRepo = new GenresRepository(fetcher)
  const genres = await genresRepo.findAll()

  const gamesRepo = new GamesRepository(fetcher)
  const games = await gamesRepo.findAll()

  const gameCatalogsRepo = new GameCatalogsRepository(fetcher)
  const gameCatalogs = await gameCatalogsRepo.findAll()

  const noticeCatalogsRepo = new NoticeCatalogsRepository(fetcher)
  const noticesCatalogs = await noticeCatalogsRepo.findAll()

  const pickupsRepo = new PickupsRepository(fetcher)
  const pickups = await pickupsRepo.findAll()

  const rankingRepo = new RankingRepository(fetcher)
  const ranking = await rankingRepo.findAll()

  const configRepo = new ConfigRepository(fetcher)
  const config = await configRepo.findAll()

  return {
    genres,
    games: GamesPresenter.transform(games),
    gameCatalogs: GameCatalogsPresenter.transform(gameCatalogs),
    noticesCatalogs: NoticeCatalogsPresenter.transform(noticesCatalogs),
    pickups: PickupsPresenter.transform(pickups),
    ranking: RankingPresenter.transform(ranking),
    distribution,
    env,
    adIds,
    config: ConfigPresenter.transform(config),
    title: distributionTitle
  }
}
/*== Get Data for top page */
const _getTopData = async () => {
  const config = await getTopData()
  return config
}
/*== Get Data for paid page */
const _getPaidData = async () => {
  const config = await getPaidData()
  return config
}
/*== Get Data for free page */
const _getFreeData = async () => {
  const config = await getFreeData()
  return config
}
export default Index
