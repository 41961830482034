import React from "react"
import styled from "styled-components"

import { color, mixin } from "~/libs/style"

export const Crosshead: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <CrossheadText>{children}</CrossheadText>
    </Wrapper>
  )
}

const Wrapper = styled.h2`
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  color: ${color.dimGray};
  background-color: ${color.whiteSmoke};
`

const CrossheadText = styled.span`
  ${mixin.maxWidth}
  display: block;
  margin: 0 auto;
`
