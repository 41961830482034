import React from "react"
import styled from "styled-components"

import { mixin, url } from "~/libs/style"

interface ProContainerProps {
  baseUrl: string
  children?: React.ReactNode
}

export const ProContainer: React.FC<ProContainerProps> = ({ baseUrl, children }) => {
  return (
    <Wrapper>
      <Header>
        <img src={`${baseUrl}assets/img/renew/paid_game_title.svg`} />
        {/* <Trapezoid>イージーゲーム PRO</Trapezoid> */}
      </Header>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${url("/assets/img/renew/paid_game_section_bg.svg")};
  margin: 0px auto;
  ${mixin.maxWidth};
  ${mixin.shadow}
}
`
const Header = styled.div`
  background: #e0e18f80;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 11px 0;
  img {
    max-width: 325.3px;
  }
`
/* const Trapezoid = styled.div`
  background: ${url("/assets/img/renew/paid_game_title_bg.svg")};
  background-repeat: no-repeat;
  width: 224px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
` */
