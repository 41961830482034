import styled, { css } from "styled-components"

import { color } from "~/libs/style"

type TopRankingLabelProps = {
  rank: number
}

export const TopRankingLabelVertical: React.FC<TopRankingLabelProps> = ({ rank }) => {
  return <Wrapper rank={rank}>{rank + "位"}</Wrapper>
}

const Wrapper = styled.span<{ rank: number }>`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  height: 20px;
  min-width: 25px;
  line-height: 20px;
  padding: 0 4px;
  font-size: 8px;
  color: ${color.white};
  text-align: center;
  background-color: ${({ rank }) => {
    switch (rank) {
      case 1:
        return color.gold
      case 2:
        return color.silverGray
      case 3:
        return color.goldenrod
      default:
        return color.dimGray
    }
  }};
  ${({ rank }) => (rank <= 3 ? TopThreeLabel : "")}
`

const TopThreeLabel = css`
  &::before {
    position: absolute;
    width: 0;
    height: 0;
    content: "";
  }

  &::before {
    border-style: solid;
    top: -2px;
    right: 8.5px;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: 22px 7px 22px 0px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`
