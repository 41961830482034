import React from "react"
import styled from "styled-components"

import { Card } from "~/components/Card"
import { HeadLink } from "~/components/HeadLink"
import { TopRankingLabelVertical } from "~/components/TopRankingLabelVertical"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory } from "~/libs/storage"
import { mixin, thumbnailSizePaid } from "~/libs/style"
type TypeProps = {
  title: string
  url: string
  moreText?: string
  ranking: Array<{
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    banner: string
    thumbnail: string
    image: {
      url: string
    }
  }>
}

export const RankingBlock: React.FC<TypeProps> = (props) => {
  const { ranking, title, url, moreText } = props
  const chunkItems = <T,>(items: T[]) =>
    items.reduce((chunks: T[][], item: T, index) => {
      const chunk = Math.floor(index / 3)
      chunks[chunk] = ([] as T[]).concat(chunks[chunk] || [], item)
      return chunks
    }, [])
  const { analyticsTrack } = useTrackingNew()
  const categoryTracking = "有料Top_有料ゲームランキング"
  const data = chunkItems(ranking.slice(0, 18))
  return (
    <>
      <HeadLink title={title} url={url} moreText={moreText} />
      <Wrapper>
        {data.map((games, groupIndex) => (
          <Grid key={groupIndex}>
            <List>
              {games.map((game, index) => (
                <Item key={index}>
                  <HorizontalLink
                    href={game.url}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                      analyticsTrack(
                        event,
                        categoryTracking,
                        `${prefixNumber(index + 1 + groupIndex * 3)}個目`,
                        `${game.gameId}：${game.title}`
                      )
                      addPlayHistory(game.gameId)
                    }}
                  >
                    <StyledCard imageURL={game.image.url}>
                      <LabelWrapper>
                        <TopRankingLabelVertical rank={index + 1 + groupIndex * 3} />
                      </LabelWrapper>
                      <Text>
                        <Body>{game.title}</Body>
                        <SubText>{game.text}</SubText>
                        <Button>人気ガチ</Button>
                      </Text>
                    </StyledCard>
                  </HorizontalLink>
                </Item>
              ))}
            </List>
          </Grid>
        ))}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  margin: 0 auto;
  display: flex;
  overflow-x: scroll;
  padding: 0px;
  ${mixin.maxWidth}
`
const Grid = styled.div`
  flex: 0 0 auto;
  width: calc(100% - 40px);
  overflow: hidden;
  margin-left: 6px;
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    width: 100%;
  }
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 7px;
`

const Item = styled.li`
  width: 100%;
  &:nth-child(3n + 2) {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
  }
  a {
    margin-right: 5px;
  }
  &:last-child a {
    margin-right: 10px;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
  width: 100%;
`
const Button = styled.button`
  background: #e9e9e9;
  border-radius: 2px;
  border: none;
  font-size: 8px;
  padding: 2px 10px;
`
const Text = styled.div`
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
`

const Body = styled.p`
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 145%;
  font-size: 14px;
`

const SubText = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
  font-size: 10px;
  line-height: 145%;
`

const StyledCard = styled(Card)`
  &::before {
    background: none;
    border: none;
  }
  img {
    border-radius: 10px;
    width: ${thumbnailSizePaid}px;
    height: ${thumbnailSizePaid}px;
  }
`
