import React from "react"
import styled from "styled-components"

import { ThumbnailNew } from "~/app/Renew/Thumbnail"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory, keys } from "~/libs/storage"
import { color, mixin, url } from "~/libs/style"
import { PopularGameCatalogsSchema } from "~/repository/PopularGameCatalogsRepository"

interface ComponentProps {
  ranking: PopularGameCatalogsSchema[]
}

export const RankingPaid: React.FC<ComponentProps> = ({ ranking }) => {
  const { analyticsTrack } = useTrackingNew()
  const visibleItemCount = 3
  return (
    <Container>
      <div>
        <Header>
          <Title>ゲームランキング</Title>
        </Header>
      </div>
      <Items>
        {ranking.slice(0, visibleItemCount).map((gameCatalog, index) => {
          const items: JSX.Element[] = []
          const rank = index + 1
          items.push(
            <Item key={index}>
              <a
                href={gameCatalog.app_url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  analyticsTrack(
                    event,
                    "SiteTop_有料ゲームランキング",
                    `${prefixNumber(rank)}個目`,
                    `${gameCatalog.app_id}：${gameCatalog.app_name}`
                  )
                  addPlayHistory(Number(gameCatalog.app_id), keys.paidHistory)
                }}
              >
                <CardWrapper>
                  <Rank rank={rank}>{rank}</Rank>
                  <Thumbnail src={gameCatalog.img_url} width={100} height={100} />
                  <Text>
                    <Badge>育成・箱庭・経営</Badge>
                    <Body>{gameCatalog.app_name}</Body>
                    <SubText>{gameCatalog.description}</SubText>
                  </Text>
                </CardWrapper>
              </a>
            </Item>
          )
          return items
        })}
      </Items>
      <ToggleButton>
        <span>
          <a
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              analyticsTrack(event, "SiteTop_ゲソてんTOPリンク", "イージーゲーム powered by ゲソてん")
            }}
            href="https://awp.gesoten.com/mypage?utm_source=top_page&utm_medium=731&utm_campaign=easygame&utm_id=influx"
          >
            イージーゲーム powered by ゲソてん
          </a>
        </span>
      </ToggleButton>
    </Container>
  )
}

const ToggleButton = styled.div`
  text-align: center;
  margin-top: 20px;
  span {
    width: 270px;
    height: 31px;
    display: flex;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    border: 1px solid #eabe4e;
    border-radius: 20px;
    color: #eabe4e;
    font-weight: 700;
    font-size: 12px;
    position: relative;
    background: #fff;

    a {
      color: #eabe4e;
    }
    &:after {
      content: ${url("/assets/img/renew/vector_right_gold.svg")};
      margin-left: 15px;
      margin-right: -10px;
    }
    div {
      display: inline-block;
    }
  }
`

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0;
`

const Container = styled.section`
  margin: 0 auto;
  ${mixin.maxWidth}
  padding: 20px 12px;
`

const Header = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
`

const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: left;
`

const Items = styled.ul`
  ${mixin.maxWidth}
  padding: 5px 0;
  margin: 0 auto;
`

const Item = styled.li`
  background: #ffffff;
  padding: 0px 10px;
  margin-bottom: 4px;
  box-shadow: 0px 2px 2px 0px #00000026;
  border-radius: 6px;
  border: 2px solid transparent;
`

const Text = styled.div`
  flex: 1;
  padding: 7px 0 3px 7px;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
`

const Body = styled.p`
  overflow: hidden;
  font-weight: 700;
  color: #3c3c3c;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SubText = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
  color: #666666;
`

const Rank = styled.div<{ rank: number }>`
  border-radius: 50%;
  background: #e2b647;
  text-align: center;
  justify-content: center;
  color: #fff;
  display: flex;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  margin-right: 7px;
  background-color: ${({ rank }) => {
    switch (rank) {
      case 1:
        return color.gold
      case 2:
        return color.silverGray
      case 3:
        return color.goldenrod
      default:
        return color.dimGray
    }
  }};
`

const Badge = styled.span`
  font-weight: 400;
  color: #999999;
  font-size: 11px;
  line-height: 17.4px;
  display: inline-block;
  padding: 1px 6px 2px 6px;
  border-radius: 4px;
  border: 1px solid #cccccc;
`

const Thumbnail = styled(ThumbnailNew)`
  border-radius: 6px;
`
