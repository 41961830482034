import React, { useState } from "react"
import styled from "styled-components"

import { AdInfeed } from "~/components/Ad"
import { Card } from "~/components/Card"
import { Crosshead } from "~/components/Crosshead"
import { GenreSelect } from "~/components/GenreSelect"
import { useTracking } from "~/contexts/Tracking"
import { reproio } from "~/libs/repro"
import { addPlayHistory } from "~/libs/storage"
import { color, mixin } from "~/libs/style"
import { Genre as GenreSchema } from "~/repository/GenresRepository"

export type GameCatalogProps = {
  gameCatalogs: Array<{
    newFlag: boolean
    updatedFlag: boolean
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    thumbnail: string
    genres: Array<{
      code: string
      name: string
    }>
  }>
  genres: GenreSchema[]
  adInfeedId: number
}

const infeedAdPosition = 6

export const GameCatalog: React.FC<GameCatalogProps> = ({ gameCatalogs, genres, adInfeedId }) => {
  const { analyticsTrack } = useTracking()
  const [activeGenre, setActiveGenre] = useState("")
  const filteredGameCatalogs = gameCatalogs.filter((gameCatalog) => {
    if (activeGenre) {
      return gameCatalog.genres.some((genre) => genre.code === activeGenre)
    }
    return true
  })

  return (
    <Wrapper>
      <Crosshead>ゲーム一覧</Crosshead>
      <GenreSelect genres={genres} selectHandler={setActiveGenre} />
      <Items>
        {filteredGameCatalogs.flatMap((gameCatalog, index) => {
          const items: JSX.Element[] = []

          if ((index || 1) % infeedAdPosition === 0) {
            const targetId = `adg_infeed_${activeGenre || "ALL"}_${index}`
            items.push(
              <li key={targetId} className="js-legacy-infeed" suppressHydrationWarning>
                <AdInfeed id={adInfeedId} targetId={targetId} />
              </li>
            )
          }

          items.push(
            <li key={index}>
              <a
                href={gameCatalog.url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  if (gameCatalog.newFlag) {
                    reproio("track", "【click】NEWラベル")
                  }
                  analyticsTrack(event, "ゲーム一覧", gameCatalog.analyticsLabel)
                  addPlayHistory(gameCatalog.gameId)
                }}
              >
                <Card imageURL={gameCatalog.thumbnail}>
                  <Text>
                    <Body>
                      {gameCatalog.newFlag ? (
                        <LabelNew>NEW</LabelNew>
                      ) : gameCatalog.updatedFlag ? (
                        <LabelUpdate>更新</LabelUpdate>
                      ) : null}
                      {gameCatalog.title}
                    </Body>
                    <SubText>{gameCatalog.text}</SubText>
                  </Text>
                </Card>
              </a>
            </li>
          )
          return items
        })}
        {filteredGameCatalogs.length <= infeedAdPosition && (
          <li>
            <AdInfeed id={adInfeedId} targetId={`adg_infeed_${activeGenre || "ALL"}_end`} />
          </li>
        )}
      </Items>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin: 20px 0;
`

const Items = styled.ul`
  ${mixin.maxWidth}
  padding: 5px 10px;
  margin: 0 auto;
  background-color: white;
`

const Text = styled.div`
  flex: 1;
  padding: 3px 0 3px 5px;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
`

const Body = styled.p`
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SubText = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
`

const LabelNew = styled.span`
  display: inline-block;
  width: 30px;
  padding: 3px 4px;
  margin: 4px 3px 0 0;
  font-size: 11px;
  line-height: 1;
  color: ${color.white};
  text-align: center;
  vertical-align: top;
  background-color: ${color.darkRed};
`

const LabelUpdate = styled(LabelNew)`
  background-color: ${color.cadmiumGreen};
`
