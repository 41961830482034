import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

import { useTracking } from "~/contexts/Tracking"
import { addPlayHistory } from "~/libs/storage"
import { color, mixin } from "~/libs/style"

type Contents = {
  gameId: number
  analyticsLabel: string
  title: string
  url: string
  banner: string
  genres: Array<{
    code: string
    name: string
  }>
}

export type PickupProps = {
  randomFlag: boolean
  contents: Contents[]
}

function shuffle(contents: Contents[]) {
  const tmp = [...contents]
  let m = tmp.length
  while (m) {
    const i = Math.floor(Math.random() * m--)
    ;[tmp[m], tmp[i]] = [tmp[i], tmp[m]]
  }
  return tmp
}

const usePickups = (randomFlag: boolean, contents: Contents[], num: number) => {
  const [games, setGames] = useState<Contents[]>([])

  useEffect(() => {
    setGames([...(randomFlag ? shuffle(contents) : contents)].slice(0, num))
  }, [])

  return games
}

export const Pickup: React.FC<PickupProps> = ({ randomFlag, contents }) => {
  const num = 3
  const games = usePickups(randomFlag, contents, num)
  const { analyticsTrack } = useTracking()

  return (
    <Wrapper id="js-legacy-pickup" suppressHydrationWarning>
      <Items>
        {!games.length ? (
          <ItemPlaceholder num={num} />
        ) : (
          games.map((game: Contents, index) => (
            <Item key={game.gameId} isLarge={index === 0}>
              <Link
                href={game.url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  const action = index === 0 ? "トップバナー大" : "トップバナー小"
                  addPlayHistory(game.gameId)
                  analyticsTrack(event, action, game.analyticsLabel)
                }}
              >
                <img src={game.banner} width="100%" height="100%" alt={game.title} />
              </Link>
            </Item>
          ))
        )}
      </Items>
    </Wrapper>
  )
}

const ItemPlaceholder: React.FC<{ num: number }> = ({ num }) => {
  return (
    <>
      {[...Array(num)].map((_, index) => (
        <Item key={index} isLarge={index === 0} />
      ))}
    </>
  )
}

const Wrapper = styled.div`
  background-color: ${color.black};
`

const Items = styled.ul`
  ${mixin.maxWidth}
  width: 100%;
  margin: 0 auto;
  font-size: 0;
`

const Item = styled.li<{ isLarge?: boolean }>`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0 solid ${color.white};
  &:nth-child(2) {
    border-right-width: 1px;
  }
  &:nth-child(3) {
    border-left-width: 1px;
  }
  &::before {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    padding-top: 45.33%;
    content: "";
  }
  ${({ isLarge }) => (isLarge ? itemCssWhenLargeSize : ``)}
`

const itemCssWhenLargeSize = css`
  display: block;
  width: 100%;
  border-bottom-width: 2px;
`

const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
`
