import getConfig from "next/config"
import Head from "next/head"
import React, { useEffect, useRef, useState } from "react"

// import { AdBanner, AdFixed, AdRectangle } from "~/components/Ad"
import { Container } from "~/components/Container"
import { Fluct } from "~/components/fluct"
import { Footer } from "~/components/Footer"
import { GameCatalog } from "~/components/GameCatalog"
import { Header } from "~/components/Header"
import { History } from "~/components/History"
import { NotePortal } from "~/components/Note"
import { NoticeCatalog } from "~/components/NoticeCatalog"
import { PageTop } from "~/components/PageTop"
import { Pickup } from "~/components/Pickup"
import { TopRanking } from "~/components/TopRanking"
import { adIds, distribution, distributionTitle, env } from "~/config"
import { ExperimentProvider } from "~/contexts/Experiment"
import { TrackingProvider } from "~/contexts/Tracking"
import { Fetcher } from "~/external/Fetcher"
import { useRepro } from "~/hooks/useRepro"
import { bustCache } from "~/libs/image"
import { ConfigPresenter } from "~/presenter/ConfigPresenter"
import { GameCatalogsPresenter } from "~/presenter/GameCatalogsPresenter"
import { GamesPresenter } from "~/presenter/GamesPresenter"
import { NoticeCatalogsPresenter } from "~/presenter/NoticeCatalogsPresenter"
import { PickupsPresenter } from "~/presenter/PickupsPresenter"
import { RankingPresenter } from "~/presenter/RankingPresenter"
import { ConfigRepository } from "~/repository/ConfigRepository"
import { GameCatalogsRepository } from "~/repository/GameCatalogsRepository"
import { GamesRepository } from "~/repository/GamesRepository"
import { GenresRepository } from "~/repository/GenresRepository"
import { NoticeCatalogsRepository } from "~/repository/NoticeCatalogsRepository"
import { PickupsRepository } from "~/repository/PickupsRepository"
import { RankingRepository } from "~/repository/RankingRepository"

type PortalAppProps = Pick<ReturnType<typeof getStaticProps> extends Promise<infer R> ? R : never, "props">

const PortalApp: React.FC<PortalAppProps["props"]> = (props) => {
  const [gameCatalogOffset, setGameCatalogOffset] = useState(500)
  const gameCatalogRef = useRef<HTMLDivElement>(null)

  const { distribution, adIds, noticesCatalogs, pickups, gameCatalogs, genres, games, ranking, config, configDomain } =
    props
  // const adFixedId = adIds[distribution].fixed
  // const adRectId = adIds[distribution].rectangle
  // const adRectMiddleId = adIds[distribution].rectMiddle
  // const adBannerId = adIds[distribution].banner
  const adInfeedId = adIds[distribution].infeed
  const baseUrl = configDomain.free
  useRepro(distribution)

  useEffect(() => {
    const offsetAfterMount = gameCatalogRef?.current?.offsetTop
    offsetAfterMount && setGameCatalogOffset(offsetAfterMount)
  }, [gameCatalogOffset])

  return (
    <ExperimentProvider configExperiments={config.experiments}>
      <Head>
        <meta property="og:title" content={`無料ゲーム| ${distributionTitle}`} />
        <meta property="og:site_name" content="無料ゲーム" />
        <meta property="og:image" content={`${baseUrl}assets/img/game_ogp.png`} />
        <meta
          property="og:description"
          content="無料で遊べる簡単ゲーム！パズル、アクション、クイズ、脳トレ、スポーツなど、さまざまなジャンルのゲームが満載。"
        />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`無料ゲーム|${distributionTitle}`} />
        <meta
          name="twitter:description"
          content="無料で遊べる簡単ゲーム！パズル、アクション、クイズ、脳トレ、スポーツなど、さまざまなジャンルのゲームが満載。"
        />
        <link rel="canonical" href={baseUrl} />
        {pickups.contents.map((pickup, index) => (
          <link key={index} rel="preload" href={pickup.banner} as="image" />
        ))}

        {/* Fluct用 */}
        <link rel="preload" as="script" href="https://pdn.adingo.jp/p.js" />
        <link rel="preconnect" href="https://sh.adingo.jp" crossOrigin="use-credentials" />

        <title>無料ゲーム|{distributionTitle}</title>
      </Head>
      <TrackingProvider categoryProp="ゲームトップ">
        <Container>
          {distribution === "portal" && <Header hasNotice={noticesCatalogs.length > 0} />}
          {/* <AdFixed id={adFixedId} /> */}
          {/* <AdBanner id={adBannerId} /> */}
          <Fluct />

          <NoticeCatalog noticesCatalogs={noticesCatalogs} />
          <Pickup {...pickups} />
          <History games={games} />

          <TopRanking ranking={ranking} />

          {/* <AdRectangle id={adRectMiddleId} /> */}

          <div ref={gameCatalogRef}>
            <GameCatalog gameCatalogs={gameCatalogs} genres={genres} adInfeedId={adInfeedId} />
          </div>

          {distribution === "portal" && <NotePortal />}
          {/* <AdRectangle id={adRectId} /> */}
          <PageTop displayOffset={gameCatalogOffset} />
          {distribution === "portal" && <Footer />}
          <script src={bustCache("/assets/js/legacy.js")}></script>
        </Container>
      </TrackingProvider>
    </ExperimentProvider>
  )
}

export const getStaticProps = async () => {
  const {
    serverRuntimeConfig: { cmsEndpoint, cmsApiKey, configDomain }
  } = getConfig()
  const fetcher = new Fetcher({ cmsEndpoint, cmsApiKey })

  const genresRepo = new GenresRepository(fetcher)
  const genres = await genresRepo.findAll()

  const gamesRepo = new GamesRepository(fetcher)
  const games = await gamesRepo.findAll()

  const gameCatalogsRepo = new GameCatalogsRepository(fetcher)
  const gameCatalogs = await gameCatalogsRepo.findAll()

  const noticeCatalogsRepo = new NoticeCatalogsRepository(fetcher)
  const noticesCatalogs = await noticeCatalogsRepo.findAll()

  const pickupsRepo = new PickupsRepository(fetcher)
  const pickups = await pickupsRepo.findAll()

  const rankingRepo = new RankingRepository(fetcher)
  const ranking = await rankingRepo.findAll()

  const configRepo = new ConfigRepository(fetcher)
  const config = await configRepo.findAll()
  return {
    props: {
      genres,
      games: GamesPresenter.transform(games),
      gameCatalogs: GameCatalogsPresenter.transform(gameCatalogs),
      noticesCatalogs: NoticeCatalogsPresenter.transform(noticesCatalogs),
      pickups: PickupsPresenter.transform(pickups),
      ranking: RankingPresenter.transform(ranking),
      distribution,
      env,
      adIds,
      config: ConfigPresenter.transform(config),
      configDomain: configDomain
    }
  }
}

export { PortalApp }
