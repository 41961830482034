import React from "react"
import styled from "styled-components"

import { HeadLink } from "~/components/HeadLink"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { addPlayHistory } from "~/libs/storage"
import { color, mixin } from "~/libs/style"
type RecommendProps = {
  recommend: Array<{
    gameId: string
    app_id: number
    title: string
    url: string
    banner: string
    thumbnail: string
  }>
}

export const Recommend: React.FC<RecommendProps> = (props) => {
  const { recommend } = props
  const { analyticsTrack } = useTrackingNew()
  const categoryTracking = "有料Top_おすすめ有料ゲーム"
  return (
    <>
      <HeadLink title="おすすめゲーム" />
      <Wrapper>
        <List>
          {recommend.slice(0, 6).map((game, index) => (
            <Item key={index}>
              <HorizontalLink
                key={index}
                href={game.url}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  analyticsTrack(
                    event,
                    categoryTracking,
                    `${prefixNumber(index + 1)}個目`,
                    `${game.title}：${game.url}`
                  )
                  addPlayHistory(game.app_id)
                }}
              >
                <Img src={game.banner} alt="" loading="lazy" onLoad={imageOnLoadHandler} />
              </HorizontalLink>
            </Item>
          ))}
        </List>
      </Wrapper>
    </>
  )
}

const imageOnLoadHandler = (e: React.SyntheticEvent<HTMLImageElement>) => {
  e.currentTarget.style.backgroundColor = color.white // 背景透過サムネイルのために変更
}
const Img = styled.img`
  position: relative;
  width: 100%;
  height: auto;
`
const Wrapper = styled.section`
  margin: 0 auto;
  padding: 10px 0px;
  ${mixin.maxWidth}
`
const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Item = styled.li`
  width: 50%;
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
`
