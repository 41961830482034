import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { HorizontalItems } from "~/app/Renew/HorizontalItems"
import { ThumbnailNew } from "~/app/Renew/Thumbnail"
import { useTrackingNew } from "~/contexts/Tracking"
import { prefixNumber } from "~/libs/analytics"
import { migrateHistories } from "~/libs/migration"
import { addPlayHistory, getPlayHistories, setPlayHistories } from "~/libs/storage"
import { mixin } from "~/libs/style"

export type GamesProps = {
  games: Array<{
    gameId: number
    analyticsLabel: string
    title: string
    text: string
    url: string
    banner: string
    thumbnail: string
    genres: Array<{
      code: string
      name: string
    }>
  }>
}

const useHistories = (games: GamesProps["games"]) => {
  const [historyGames, setHistoryGames] = useState<GamesProps["games"]>([])

  useEffect(() => {
    migrateHistories()

    const gameIds = getPlayHistories()
    const newHistoryGames = gameIds
      .map((gameId) => games.find((game) => game.gameId === gameId))
      .filter((game): game is NonNullable<typeof game> => game !== undefined)

    setPlayHistories(newHistoryGames.map((historyGame) => historyGame.gameId))

    if (newHistoryGames.length) {
      setHistoryGames(newHistoryGames)
    }
  }, [])

  return historyGames
}

export const History: React.FC<GamesProps> = ({ games }) => {
  const historyGames = useHistories(games)
  const { analyticsTrack } = useTrackingNew()

  if (!historyGames.length) {
    return null
  }

  return (
    <Wrapper>
      <Title>
        <CrossheadText>最近プレイした無料ゲーム</CrossheadText>
      </Title>
      <HorizontalItems>
        {historyGames.map((game, index) => (
          <Item key={index}>
            <HorizontalLink
              key={index}
              href={game.url}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                analyticsTrack(
                  event,
                  "SiteTop_無料_最近プレイしたゲーム",
                  `${prefixNumber(index + 1)}個目`,
                  `${game.analyticsLabel}：${index + 1}`
                )
                addPlayHistory(game.gameId)
              }}
            >
              <ThumbnailStyled src={game.thumbnail} width={100} height={100} />
              <GameTitle>{game.title}</GameTitle>
            </HorizontalLink>
          </Item>
        ))}
      </HorizontalItems>
    </Wrapper>
  )
}

const Title = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 23.2px;
  color: #3c3c3c;
`

const CrossheadText = styled.span`
  ${mixin.maxWidth}
  display: block;
  margin: 0 auto;
`

const Wrapper = styled.section`
  margin: 0px auto;
  margin-bottom: 8px;
  background: #ffffff;
  padding: 24px 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  ${mixin.maxWidth}
  ${mixin.shadow}
`

const HorizontalLink = styled.a`
  position: relative;
  display: block;
  padding: 5px 0;
`

const Item = styled.li`
  max-width: 100px;
  padding-right: 16px;
  a {
    margin-right: 5px;
  }
  &:last-child a {
    margin-right: 10px;
  }
`

const GameTitle = styled.p`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  margin-top: 8px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
  line-height: 18.2px;
  text-overflow: clip;
  white-space: normal;
`

const ThumbnailStyled = styled(ThumbnailNew)`
  border-radius: 5px;
`
