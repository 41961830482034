import { env } from "~/config"
import { adjustImgixParam, buildAssetsPath } from "~/libs/image"
import { BannersPaidSchema } from "~/repository/BannersRepository"
const isLocal = env === "local"
const buildBannerPath = buildAssetsPath("banner")
const adjustBannerParam = adjustImgixParam("banner")
type banner = {
  app_id: number
  url: string
  banner: string
  title: string
  analytics: {
    category: string
    label: string
  }
  isPaid: boolean
}
export class BannersPaidPresenter {
  static transform(bannersPaid: BannersPaidSchema): banner[] {
    return bannersPaid.contents.reduce<banner[]>((result, content) => {
      if (content.game) {
        const { game } = content
        const analyticsLabel = `${game.app_name}：${game.app_url}`
        const image = !game.image.url
          ? ""
          : isLocal
          ? adjustBannerParam(game.image.url)
          : buildBannerPath(game.id, "paid")
        result.push({
          app_id: game.app_id ?? 0,
          url: game.app_url,
          banner: image,
          title: game.app_name,
          analytics: {
            category: "SiteTop_有料ゲーム・特集バナー",
            label: analyticsLabel
          },
          isPaid: true
        })
      }
      return result
    }, [])
  }
}
